import React from 'react';
import { Link } from 'react-router-dom';

import {
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';

import { BsSearch } from 'react-icons/bs';
import { RxFilePlus } from 'react-icons/rx';
import { FaUser, FaChevronDown } from 'react-icons/fa';
import { GoLaw } from 'react-icons/go';

type SideBarProps = {
  openSideBar: boolean,
  open: any,
  setOpen: any,
  setSearched: any
}

export function Sidebar({ openSideBar, open, setOpen, setSearched }: Readonly<SideBarProps>) {
  const userName = React.useMemo(() => localStorage.getItem('user_name')?.toUpperCase(), [])
  const handleOpen = (value: React.SetStateAction<number>) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className={`h-full fixed bg-gray-50 transition-all duration-300 ease-in-out ${openSideBar ? 'w-[250px]' : 'w-[50px] sm:w-[84px]'}`}>
      {openSideBar ? (
        <>
        <div className="mb-2 p-5">
          <img src={require('../../assets/logo-pge.png')} alt="Logo" className="p-7"/>
        </div>
        <Typography 
          variant="h6" 
          color="green"
          className="flex items-center ml-4"
        >
          <FaUser  className="h-5 w-5 mr-5 ml-4" color="gren"/>
          {userName}
        </Typography>
        </>
      ) : (
        <><GoLaw className="h-7 w-7 mt-[15px] ml-[10px] sm:hidden sm:h-10 sm:w-10 text-blue-gray-700"/>
        <Typography variant="h4" color="gray" className='mt-9 ml-4 mb-3 hidden sm:block'>
          PGE
        </Typography></>
      )}
      <div className="flex flex-col min-w-[240px] font-sans text-base font-normal text-blue-gray-700 mt-6 p-0">
        <Accordion
          open={open === 1}
          icon={
            openSideBar ?
            <FaChevronDown
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 mr-1 transition-transform ${open === 1 ? '' : 'rotate-90'}`}
            /> : ''
          }
          className={`${open === 1 ? 'bg-gray-200' : ''} `}
        >
          {openSideBar ? (
            <>
            <ListItem  className="p-0 hover:bg-gray-200 rounded-none" selected={open === 1}>
              <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3" >
                <ListItemPrefix>
                  <BsSearch className="h-5 w-5 ml-6" />
                </ListItemPrefix>
                <Typography color="blue-gray" className="mr-auto font-black">
                  Consultar
                </Typography>
              </AccordionHeader>
            </ListItem>
            <Link to="/consulta">
              <AccordionBody className="py-0 ml-8">
                <List className="ml-12">
                  <div className="font-black mt-4 mb-4">
                    Precedentes Administrativos
                  </div>
                </List>
              </AccordionBody>
            </Link>
            </>
          ) : (
            <Link to="/consulta">
              <ListItem className="p-0 hover:bg-gray-200 rounded-none w-[50px] sm:w-[84px]" selected={open === 1}>
                <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 sm:p-3 sm:mb-2 sm:mt-2" >
                  <BsSearch className="h-8 w-8 ml-3" />
                </AccordionHeader>
              </ListItem>
            </Link>
          )}
        </Accordion>
        <Accordion
          open={open === 2}
          icon={
            openSideBar ?
            <FaChevronDown
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4  mr-1 transition-transform ${open === 2 ? '' : 'rotate-90'}`}
            /> : ''
          }
          className={`${open === 2 ? 'bg-gray-200' : ''} `}
        >
          {openSideBar ? (
            <>
            <ListItem className="p-0 hover:bg-gray-200 rounded-none" selected={open === 2}>
              <AccordionHeader onClick={() => {handleOpen(2); setSearched(false)}} className="border-b-0 p-3">
                <ListItemPrefix>
                  <RxFilePlus className="h-5 w-5 ml-6" />
                </ListItemPrefix>
                <Typography color="blue-gray" className="mr-auto font-black">
                  Cadastro
                </Typography>
              </AccordionHeader>
            </ListItem>
            <Link to="/cadastro">
              <AccordionBody className="py-0 ml-8">
                <List className="ml-12">
                  <div className="font-black mt-4 mb-4">
                    Precedentes Administrativos
                  </div>
                </List>
              </AccordionBody>
            </Link>
            </>
          ) : (
            <Link to="/cadastro">
              <ListItem className="p-0 hover:bg-gray-200 rounded-none w-[50px] sm:w-[84px]" selected={open === 2}>
                <AccordionHeader onClick={() => {handleOpen(2); setSearched(false)}} className="border-b-0 sm:p-3 sm:mb-2 sm:mt-2">
                  <RxFilePlus className="h-8 w-8 ml-3" />
                </AccordionHeader>
              </ListItem>
            </Link>
          )}
        </Accordion>
      </div>
    </div>
  );
};