import React, { useEffect, useState, useMemo } from 'react';
import { Card, Typography, Input, Button, Select, Option } from '@material-tailwind/react';
import { MdOutlineSettingsBackupRestore } from 'react-icons/md';
import { SearchLawDto } from '../../dtos/SearchLawDto.model';
import { Pagination } from '../Pagination';
import AccordionLaw from '../AccordionLaw';
interface Law {
  id: number
  description: string;
  id_alfresco: string;
  document_date: string;
  concerned_person_name: string;
  document_number: string;
  law_type_id: number;
  organization_origin_id: number;
  subject: string;
  domain_name: string;
  organization_name: string;
}

type ResultProps = {
  listLaws: Law[];
  setResultLaws: React.Dispatch<React.SetStateAction<any>>;
  getSearch: ( paramsSearch: any ) => Promise<any>;
  searchParams: any;
  lawsCount: any;
  currentPage: number;
  itemsPerPage: number;
  totalPage: number;
}
// eslint-disable-next-line
const ResultPublicSearchLaws = ({listLaws, setResultLaws, getSearch, searchParams, lawsCount, itemsPerPage, currentPage, totalPage}: ResultProps) => {
  const [ searchInList, setSearchInList ] = useState<string>('')
  const [ ordemList, setOrdemList ] = useState<string | undefined>()
  const [ objListLaws, setObjListLaws ] = useState<Law[]>(listLaws)

  const documentDateInitial = useMemo(() => searchParams.documentDateInitial, []);
  const documentDateEnd = useMemo(() => searchParams.documentDateEnd, []);
  const documentNumber = useMemo(() => searchParams.documentNumber, []);
  const processNumber = useMemo(() => searchParams.processNumber, []);
  const subject = useMemo(() => searchParams.subject, []);
  const concernedPersonName = useMemo(() => searchParams.concernedPersonName, []);
  const radioDomain = useMemo(() => searchParams.radioDomain, [])
  const radioAll = useMemo(() => searchParams.radioAll, [])
  const organizationOrigin = useMemo(() => searchParams.organizationOrigin, []);
  const area = useMemo(() => searchParams.area, []);

  const pages = Math.ceil(lawsCount / itemsPerPage);

  const [page, setPage] = useState(1)
  
  const handleSubmit = async () => {
    const paramsSearch = new SearchLawDto(
      radioAll, 
      radioDomain,
      area,
      organizationOrigin,
      concernedPersonName,
      documentNumber,
      processNumber,
      subject,
      documentDateInitial,
      documentDateEnd,
      page
    )
    await getSearch( paramsSearch )
  }

  useEffect(() => {
    handleSubmit()
  },[page])
  
  useEffect(() => {
    const filteredLaws = listLaws.filter(law => {
      return law
    });
    setObjListLaws(filteredLaws)
  },[currentPage])
 
  useEffect(() => {
    if (Number(ordemList) === 1) {
      objListLaws.sort((a, b) => {
        return new Date(a.document_date).getTime() - new Date(b.document_date).getTime();
      });
    } else {
      objListLaws.sort((a, b) => {
        return new Date(b.document_date).getTime() - new Date(a.document_date).getTime();
      });
    }
  },[ordemList])

  useEffect(() => {
    const searchText = searchInList.toLowerCase();
    const filteredLaws = listLaws.filter(law => {
      return law.description.toLowerCase().includes(searchText)
    });

    setObjListLaws(filteredLaws)
  },[searchInList])

  const newSearch = () => {
    setResultLaws([])
  }

  useEffect(() => {
    const table: any = document.getElementById('table');
    if (table !== null) {
      table.scrollTop = 0;
    }
  }, [objListLaws]);

  return(
    <Card className="h-[calc(100vh-50px)] lg:h-[calc(100vh-125px)] bg-gray-444 shadow-lg rounded-none w-auto sm:w-[40rem] lg:w-[70rem]">
      <div className="text-center mt-6 text-1xl text-black font-bold lg:text-2xl sm:text-center">
        BUSCA DE PRECEDENTES ADMINISTRATIVOS
      </div>
      <div className="mb-4 gab-2 flex flex-row mr-5">
        <div className='mt-6 ml-5 basis-full'>
          <Input
            type="text"
            label="Digite aqui sua busca"
            value={searchInList}
            onChange={(e) => setSearchInList(e.target.value)}
            crossOrigin={undefined}      
          />
        </div>
        <div className="ml-1 mt-6">
          <Button onClick={newSearch} className="flex items-center gap-2 bg-gray-555">
            <MdOutlineSettingsBackupRestore  className='h-4 w-4 hidden sm:block'/>
            Voltar
          </Button>
        </div>
      </div>
      <div className="gap-2 flex items-center">
        <div className="ml-5">
          <Typography className='text-sm text-gray-900'>
            Ordenar por
          </Typography>
        </div>
        <div className="basis-0 mr-5">
          <Select 
            label='Selecione'
            onChange={(e) => setOrdemList(e)} 
            selected={(element) => element && React.cloneElement(element, {className: 'flex items-center px-0 gap-2 pointer-events-none'})} 
          >
            <Option value='1'>Mais recentes</Option>
            <Option value='2'>Menos recentes</Option>
          </Select>
        </div>
      </div>
      <Typography className='text-sm text-gray-900 mt-4 mb-2 ml-5'>
        Exibindo {(page-1)*itemsPerPage + 1}-{currentPage == totalPage ? lawsCount : currentPage*itemsPerPage} de {lawsCount} resultados 
      </Typography>
      <div id='table'className='overflow-y-auto mb-[80px] snap-always snap-center'>
        {objListLaws.map((
          law: Law
        ) => (
          <AccordionLaw 
            key={law.id} name={law.concerned_person_name}  documentNumber={law.document_number} documentDate={law.document_date} subject={law.subject} 
            domainName={law.domain_name} organizationName={law.organization_name} description={law.description} idAlfresco={law.id_alfresco}
          />
        ))}
      </div>
      <div className='ml-5 flex items-center gap-1 absolute bottom-6'>
        {pages > 1 && (
          <Pagination currentPage={currentPage} lastPage={pages}  maxLength={7} setCurrentPage={setPage}/>
        )}
      </div>
    </Card>
  )
}

export default ResultPublicSearchLaws
