import { InternalLawDto } from '../../dtos/internalLaw.model';
import { HiDocument } from 'react-icons/hi';
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../Modal';
import { BsFileEarmarkPdf } from 'react-icons/bs';
import { useDonwloadLaw } from '../../hooks/useDonwloadLaw';
import { Spinner } from '@material-tailwind/react';
import { useEffect, useState } from 'react';

const InternalLawsTable = ({
  laws,
  showLaw
}: {
  laws?:   any,
  showLaw: any
}) => {
  const { idLoading, downloadPdfLaw } = useDonwloadLaw();
  const navigate = useNavigate();
  const [ reducedSize, setReducedSize ] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setReducedSize(window.innerWidth < 1601);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <>
      <div className='xl:hidden'>
      {
        laws?.map((law: InternalLawDto) => (
          <table key={`${law.id}`} className="table-fixed w-full border-solid border-b-[5px] border-b-gray-500 text-sm mb-5">
            <tbody>
              <tr className='border-solid border-b-[2px]'>
                <td className="font-black w-[40%]">
                  Área
                </td>
                <td className="w-[60%] text-right">
                  {law.area}
                </td>
              </tr>
              <tr className='border-solid border-b-[2px]'>
                <td className="font-black w-[40%]">
                  Tipo
                </td>
                <td className="w-[60%] text-right">
                  {law.lawType}
                </td>
              </tr>
              <tr className='border-solid border-b-[2px]'>
                <td className="font-black w-[40%]">
                  Orgão de Origem
                </td>
                <td className="w-[60%] text-right">
                  {law.organizationOrigin}
                </td>
              </tr>
              <tr className='border-solid border-b-[2px]'>
                <td className="font-black w-[40%]">
                  Nome do Interessado
                </td>
                <td className="w-[60%] text-right">
                  {law.concernedPersonName}
                </td>
              </tr>
              <tr className='border-solid border-b-[2px]'>
                <td className="font-black w-[40%]">
                  Número do Documento
                </td>
                <td className="w-[60%] text-right">
                  {law.documentNumber}
                </td>
              </tr>
              <tr className='border-solid border-b-[2px]'>
                <td className="font-black w-[40%]">
                  Número do Processo
                </td>
                <td className="w-[60%] text-right text-xs sm:text-sm">
                  {law.processNumber}
                </td>
              </tr>
              <tr className='border-solid border-b-[2px]'>
                <td className="font-black w-[40%]">
                   Data do Documento
                </td>
                <td className="w-[60%] text-right">
                  {law.documentDate?.toString()}
                </td>
              </tr>
              <tr className='border-solid border-b-[2px]'>
                <td className="font-black w-[40%]">
                  Anexo 
                </td>
                <td className='grid justify-items-end'>
                  {idLoading == law.file ? (
                      <Spinner className="h-4 w-4 mt-1 mb-1"/>
                    ) : (
                      <BsFileEarmarkPdf className="cursor-pointer text-xl mt-1 mb-1" onClick={
                        async () => await downloadPdfLaw(law.file ?? '')
                      }/>
                    )
                  }
                </td>
              </tr>
              <tr className='border-solid border-b-[2px]'>
                <td className="font-black w-[40%]">
                  Ações 
                </td>
                <td className="text-right">
                  <button onClick={()=> showLaw(law.id)} className="rounded-sm p-[0.5] border-solid border-[1px] border-gray-500 mr-2 mt-1">
                    <HiDocument className="text-xl" />
                  </button>

                  <button onClick={()=> navigate(`/law/edit/${law.id}`)} className="rounded-sm p-[0.5] border-solid border-[1px] border-gray-500 mr-2">
                    <MdEdit className="text-xl" />
                  </button>
                  <Modal idLaw={law.id}/>
                </td>
              </tr>
            </tbody>
          </table>
        ))
      }
      </div>
      {/* Tabela para telas grandes */}
      <div className='hidden xl:block'>
        <table className="table-fixed w-full text-left text-sm">
          <thead>
            <tr className="border-solid border-b-[1px] border-b-gray-500">
              <th className="w-[6%] pb-2 tracking-wide">Área</th>
              <th className="w-[6%] pb-2 tracking-wide">Tipo</th>
              <th className="w-[10%] pb-2 tracking-wide">Órgão de Origem</th>
              <th className="w-[8%] pb-2 tracking-wide">Nome do Interessado</th>
              <th className="w-[6%] pb-2 tracking-wide">N. Documento</th>
              <th className="w-[13%] pb-2 tracking-wide">N. Processo</th>
              <th className="w-[6%] pb-2 tracking-wide">Data do Documento</th>
              <th className="w-[2%] pb-2 text-center tracking-wide">Anexo</th>
              <th className="w-[4%] pb-2 text-right lg:text-center tracking-wide">Ações</th>
            </tr>
          </thead>

          <tbody>
            {
              laws?.map((law: InternalLawDto) => (
                <tr key={`${law.id}`} className="border-solid border-b-[1px] border-b-gray-500">
                  <td className=" py-2 truncate whitespace-normal">{law.area}</td>
                  <td className=" py-2 whitespace-normal">{law.lawType}</td>
                  <td className=" truncate whitespace-normal py-2">{law.organizationOrigin}</td>
                  <td className=" py-2 truncate whitespace-normal">{law.concernedPersonName}</td>
                  <td className=" py-2 whitespace-normal">{law.documentNumber}</td>
                  <td className=" py-2 whitespace-normal">{law.processNumber}</td>
                  <td className=" py-2 whitespace-normal">{law.documentDate?.toString()}</td>
                  <td className=" py-2 text-center">
                  {idLoading == law.file ? (
                      <Spinner className="h-4 w-4 ml-4"/>
                    ) : (
                      <BsFileEarmarkPdf className="cursor-pointer text-xl ml-4" onClick={
                        async () => await downloadPdfLaw(law.file ?? '')
                      }/>
                    )
                  }
                  </td>
                  <th className="w-[4%] py-2 text-right">
                    <button onClick={()=> showLaw(law.id)} className="rounded-sm border-solid border-[1px] border-gray-500 mr-2">
                      <HiDocument className="text-xl" />
                    </button>

                    <button onClick={()=> navigate(`/law/edit/${law.id}`)} className="rounded-sm border-solid border-[1px] border-gray-500 mr-2">
                      <MdEdit className="text-xl" />
                    </button>
                    <Modal idLaw={law.id}/>
                  </th>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </>
  )
};

export default InternalLawsTable;
