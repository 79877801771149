import React from 'react';
import { Button, ButtonGroup } from '@material-tailwind/react';
import { getPaginationItems } from '../../utils/usePagination';
import { GrNext } from 'react-icons/gr'
import { MdOutlineArrowBackIos } from 'react-icons/md';

type Props = {
  currentPage: number;
  lastPage: number;
  maxLength: number;
  setCurrentPage: (page: number) => void;
};

export function Pagination({ currentPage, lastPage, maxLength, setCurrentPage}: Readonly<Props>) {
  const mobile = window.innerWidth > 750 ? '' : 'p-[9px]'
  const getItemProps = (index: React.SetStateAction<number>) => (
    { className: `${mobile} ${currentPage === (index) ? 'bg-gray-555 text-white border-gray-400' : 'border-gray-400  text-green-600'}` } as any
  );
  const pageNums = getPaginationItems(currentPage, lastPage, maxLength);

  return (
    <ButtonGroup variant="outlined" size='sm'>
      <Button 
        className={`${mobile} ${currentPage === 1 ? 'border-gray-400 text-gray-600' : 'border-gray-400 text-green-600'}`} 
        onClick={(e) => setCurrentPage(currentPage - 1)} 
        disabled={currentPage === 1}
      >
        {window.innerWidth > 750 ? ('Página Anterior') : (<MdOutlineArrowBackIos className={currentPage === 1 ? 'border-gray-400 text-gray-600' : 'border-gray-400 text-green-600'}/>)}
      </Button>
      {pageNums.map((pageNum, idx) => (
        <Button key={idx} {...getItemProps(pageNum)} onClick={(e) => setCurrentPage(Number(pageNum))}> {!isNaN(pageNum) ? pageNum : '...'}</Button>              
      ))}
      <Button 
        className={`${mobile} ${currentPage === lastPage ? 'border-gray-400 text-gray-600' : 'border-gray-400 text-green-600'}`} 
        onClick={(e) => setCurrentPage(currentPage + 1)} 
        disabled={currentPage === lastPage}
      >
        {window.innerWidth > 750 ? ('Próxima Página') : (<GrNext className={currentPage === 1 ? 'border-gray-400 text-gray-600' : 'border-gray-400 text-green-600'}/>)}
      </Button>
    </ButtonGroup>
  );
};
