import { useEffect, useState } from 'react'

export const useOpenSideBar = () => {
  const [ openSideBar, setOpenSideBar ] = useState<boolean>(true)
  const [ open, setOpen ] = useState(1)

  useEffect(() => {
    const handleResize = () => {
      setOpenSideBar(window.innerWidth > 750);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[])

  return { openSideBar, setOpenSideBar, open, setOpen }
}