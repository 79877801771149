import { Routes, Route } from 'react-router-dom';
import { Login } from '../pages/Login'
import { Search } from '../pages/Search';
import { Registration } from '../pages/Registration';
import { PrivateRoute } from '../components/PrivateRouter';
import { Layout } from '../components/Layout';
import { PublicSearchLaws } from '../pages/PublicConsultation';
import { useOptionSearch } from '../utils/useOptionSearch';
import { useOpenSideBar } from '../utils/useOpenSideBar';

export function AppRoutes () {
  const { searched, setSearched } = useOptionSearch();
  const { open, setOpen, openSideBar, setOpenSideBar } = useOpenSideBar()

  return (
    <Routes>
      <Route path='/' element={<Login />}/>
      <Route path='/consulta_precedentes' element={<PublicSearchLaws />} />
      <Route
        path='/consulta'
        element={
          <Layout open={open} setOpen={setOpen} openSideBar={openSideBar} setOpenSideBar={setOpenSideBar} searched={searched} setSearched={setSearched}>
            <PrivateRoute>
              <Search searched={searched} setSearched={setSearched}/>
            </PrivateRoute>
          </Layout>
        }
      />

      <Route 
        path='/cadastro' 
        element={
          <Layout open={open} setOpen={setOpen} openSideBar={openSideBar} setOpenSideBar={setOpenSideBar} searched={searched} setSearched={setSearched}>
            <PrivateRoute>
              <Registration setSearched={setSearched} setOpen={setOpen}/>
            </PrivateRoute>
          </Layout>
        }/>

      <Route path='/law/edit/:id' element={
        <Layout open={open} setOpen={setOpen} openSideBar={openSideBar} setOpenSideBar={setOpenSideBar} searched={searched} setSearched={setSearched}>
          <PrivateRoute>
            <Registration setSearched={setSearched} setOpen={setOpen}/>
          </PrivateRoute>
        </Layout>
      } />
    </Routes>
  );
};
