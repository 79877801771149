import { Card, Typography, Button, Spinner } from '@material-tailwind/react';
import { BsFileEarmarkPdf } from 'react-icons/bs';
import MaskedInput from 'react-input-mask';
import { useDonwloadLaw } from '../../hooks/useDonwloadLaw';
import { convertDate, formatProcessNumber } from '../../utils/masks'


type ExtractProps = {
  documentDate: string
  documentNumber: string | undefined
  processNumber: string
  subject: string | undefined
  concernedPersonName: string | undefined
  organizationOrigin: string | undefined
  area: string | undefined
  domain: string | undefined
  idAlfresco: string | undefined
  setGetIDLaw: any
}

export function Extract({documentDate, documentNumber, processNumber, subject, concernedPersonName, organizationOrigin, area, domain, idAlfresco, setGetIDLaw }: Readonly<ExtractProps>) {
  const { downloadPdfLaw, loading } = useDonwloadLaw()

  const donwloadLaw = async (id_alfresco: string) => {
    await downloadPdfLaw(id_alfresco)
  }

  const backSearch = () =>{
    setGetIDLaw(undefined)
  }

  return (
    <div>
      <Card className="w-full h-auto rounded-sm">
        <Typography variant="h5" className="p-6 text-gray-400" >
          Informações 
        </Typography>
        <table className="table-fixed mt-5 ml-6 mr-6 mb-6 h-[36rem]">
          <tbody className="border-t border-gray-400">
            <tr>
              <td className="border-b border-gray-400 w-[20%]">
                <Typography variant="small" color="blue-gray" className="font-black text-gray-400">
                  Área
                </Typography>
              </td>
              <td className="border-b border-gray-400 h-1 w-[80%]">
                <Typography variant="small" color="blue-gray" className="font-normal text-gray-400">
                  {area}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className="border-b border-gray-400 w-[20%]">
                <Typography variant="small" color="blue-gray" className="font-black text-gray-400">
                  Tipo
                </Typography>
              </td>
              <td className="border-b border-gray-400 h-1 w-[80%]">
                <Typography variant="small" color="blue-gray" className="font-normal text-gray-400">
                  {domain}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className="border-b border-gray-400 w-[20%]">
                <Typography variant="small" color="blue-gray" className="font-black text-gray-400">
                  Orgão de Origem
                </Typography>
              </td>
              <td className="border-b border-gray-400 h-1 w-[80%]">
                <Typography variant="small" color="blue-gray" className="font-normal text-gray-400">
                  {organizationOrigin}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className="border-b border-gray-400 w-[20%]">
                <Typography variant="small" color="blue-gray" className="font-black text-gray-400">
                  Nome do Interessado
                </Typography>
              </td>
              <td className="border-b border-gray-400 h-1 w-[80%]">
                <Typography variant="small" color="blue-gray" className="font-normal text-gray-400">
                  {concernedPersonName}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className="border-b border-gray-400 w-[20%]">
                <Typography variant="small" color="blue-gray" className="font-black text-gray-400">
                  Número do Documento
                </Typography>
              </td>
              <td className="border-b border-gray-400 h-1 w-[80%]">
                <MaskedInput value={documentNumber} mask='9999/9999' disabled className="font-normal text-gray-400 text-sm"></MaskedInput>
              </td>
            </tr>
            <tr>
              <td className="border-b border-gray-400 w-[20%]">
                <Typography variant="small" color="blue-gray" className="font-black text-gray-400">
                  Número do Processo
                </Typography>
              </td>
              <td className="border-b border-gray-400 h-1 w-[80%]">
                <Typography className="font-normal text-gray-400 text-sm">
                {formatProcessNumber(processNumber)}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className="border-b border-gray-400 w-[20%]">
                <Typography variant="small" color="blue-gray" className="font-black text-gray-400">
                  Assunto
                </Typography>
              </td>
              <td className="text-justify border-b border-gray-400 h-1 w-[80%]">
                <Typography variant="small" color="blue-gray" className="font-normal text-gray-400">
                  {subject}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className="border-b border-gray-400 w-[20%]">
                <Typography variant="small" color="blue-gray" className="font-black text-gray-400">
                  Data do Documento
                </Typography>
              </td>
              <td className="border-b border-gray-400 h-1 w-[80%]">
                <Typography variant="small" color="blue-gray" className="font-normal text-gray-400">
                  {convertDate(documentDate)}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className="border-b border-gray-400 w-[20%]">
                <Typography variant="small" color="blue-gray" className="font-black text-gray-400">
                  Anexo 
                </Typography>
              </td>
              <td className="border-b border-gray-400 h-1 w-[80%]">
                {loading? (
                  <Spinner className="h-4 w-4"/>
                ) : (
                  <BsFileEarmarkPdf onClick={() => donwloadLaw(String(idAlfresco))} className='cursor-pointer h-4 w-4'/>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
      <Button color="orange" className="mt-4 ml-6 rounded-sm" onClick={backSearch}>
        Voltar
      </Button>
    </div>
  );
}