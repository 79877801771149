import { Button, Dialog, DialogBody, DialogFooter, Typography} from '@material-tailwind/react';
import { FiTrash2 } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { useDeleteLaw } from '../../hooks/useDeleteLaw';

type ModalProps = {
  idLaw: number | undefined;
}

export function Modal({ idLaw }: Readonly<ModalProps>) {
  const { getDeleteLaw, open, handleOpen } = useDeleteLaw() 

  return (
    <>
      <button onClick={handleOpen} className="rounded-sm p-[0.5] border-solid border-[1px] border-gray-500 sm:mr-0 xl:mr-2">
        <FiTrash2 className="text-xl"/> 
      </button>
      <Dialog open={open} size='xs' handler={handleOpen} className='bg-gray-200 flex flex-col items-center'>
        <div className="flex justify-end w-full">
          <GrClose
            onClick={handleOpen}
            className="mt-4 mr-4 cursor-pointer"
          />
        </div>
        <img src={require('../../assets/caution.png')} alt='Logo' className='w-28'/>
        <DialogBody className='p-0'>
          <Typography className='font-bold text-sm sm:text-lg'>
            Deseja excluir este registro?
          </Typography>
        </DialogBody>
        <DialogFooter  className='mb-3'>
          <Button variant='gradient' color='red' className='mr-2' onClick={() => getDeleteLaw(idLaw)}>
            Sim
          </Button>
          <Button variant='gradient' color='blue-gray' onClick={handleOpen}>
            Não
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}