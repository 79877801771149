import {
  Typography,
  Card,
  Input,
  Button
} from '@material-tailwind/react';

import { useEffect, useState, useMemo } from 'react';

import MaskedInput from 'react-input-mask';
import Select from 'react-select';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import InternalLawsTable from '../../components/InternalLawsTable';
import { Extract } from '../../components/Extract';
import { useShowLaw } from '../../hooks/useShowLaw';
import { Pagination } from '../../components/Pagination';
import { useSearchInternalLaws } from '../../hooks/useSearchInternalLaws';
import { getAreas, getDomains, getOrganizationOrigins } from '../../services/service';
import { AreaDto } from '../../dtos/areaDto.model';
import { OrganizationOriginDto } from '../../dtos/organizationOriginDto.model';
import { useForm } from '../../utils/useForm';
import { maskBuildProcessNumber } from '../../utils/masks';
import { AlertType } from '../../models/alert';
import { useAlertContext } from '../../hooks/useAlert';
import { DomainDto } from '../../dtos/domainDto.model';

type SearchProps = {
  searched: boolean;
  setSearched: React.Dispatch<React.SetStateAction<boolean>>
}

export function Search({searched, setSearched}: Readonly<SearchProps>) {
  const { createAlert } = useAlertContext();

  const {
    resultLaws, getLaws, setResultLaws, loading, lawsCount, itemsPerPage, currentPage
  } = useSearchInternalLaws();

  const {
    showLaw, getIdLaw, setGetIDLaw, documentDate, documentNumber, processNumber, subject, concernedPersonName, organizationOrigin, area, domain, idAlfresco
  } = useShowLaw();

  const [ areaLabel, setAreaLabel ] = useState(null)
  const [ domainLabel, setDomainLabel ] = useState(null)
  const [ organizationOriginLabel, setOrganizationOriginLabel ] = useState(null)

  const pages = Math.ceil(lawsCount / itemsPerPage);
  const [page, setPage] = useState(1);

  const [areaList, setAreaList] = useState<AreaDto[]>();
  const [domainList, setDomainList ] = useState<DomainDto[]>([]);
  const [organizationOriginList, setOrganizationOriginList] = useState<OrganizationOriginDto[]>();
  const isClearable = useMemo(() => true, []);
  const [documentDateParams, setDocumentDateParams] = useState<DateValueType>(null);

  const domainAll: DomainDto[] = [
    { label: 'Todos', value: 99 }
  ]
  const domainListAll = [...domainList, ...domainAll]

  useEffect(() => {
    const fetchData = async () => {
      const OrganizationOriginList = await getOrganizationOrigins()
      const domainData = await getDomains();
      const areaData = await getAreas();

      setOrganizationOriginList(OrganizationOriginList.data.data);
      setDomainList(domainData.data.data);
      setAreaList(areaData.data.data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (searched)
      getLaws(values, page);
  }, [page]);

  useEffect(() => {
    const filteredLaws = resultLaws.filter(law => {
      return law
    });

    setResultLaws(filteredLaws);
  }, [currentPage]);

  const clearForm = () => {
    window.location. reload();
  };

  const initialState = {
    areaId: '',
    domainId: '',
    organizationOriginId: '',
    concernedPersonName: '',
    documentNumber: '',
    processNumber: '',
    subject: '',
    documentDate: '',
  };

  const { onChange, onSubmit, values }: any = useForm(
    () => console.log('init form'),
    initialState
  );

  const changeArea = (e: any) => {   
    if (e) {
      values.areaId = e.value;
      setAreaLabel(e.value)
    } else {
      values.areaId = '';
      setAreaLabel(null)
    }
  };

  const changeDomainType = (e: any) => {   
    if (e) {
      values.domainId = e.value;
      setDomainLabel(e.value)
    } else {
      values.domainId = '';
      setDomainLabel(null)
    }
  };

  const changeOrganizationOrigin = (e: any) => {   
    if (e) {
      values.organizationOriginId = e.value;
      setOrganizationOriginLabel(e.value)
    } else {
      values.organizationOriginId = '';
      setOrganizationOriginLabel(null)
    }
  };

  const handleDateValueChange = (newValue: DateValueType) => {
    setDocumentDateParams(newValue);
    values.documentDate = newValue?.startDate;
  };

  const searchCallback = () => {
    if (
      values.areaId || values.domainId || values.organizationOriginId || values.concernedPersonName || values.documentNumber || values.processNumber || values.subject || values.documentDate
    ) {
      setSearched(true);
      setPage(1);
      getLaws(values, page);
    } else {
      createAlert({
        alertType: AlertType.error,
        message: 'Preencha pelo menos um campo!',
        autoHide: true
      });
    }
  };

  const customStyles = {
    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: 'bg-gray-200',
      borderRadius: '7px',
      borderWidth: state.isFocused ? '2px' : '1px',
      borderColor: state.isFocused ? 'black' : 'rgb(176 190 197)',
      '&:hover': {
        borderWidth: state.isFocused ? 2 : 1,
        borderColor: state.isFocused ? 'black' : 'rgb(176 190 197)',
      },
      border: '1px solid border-blue-gray-200',
      boxShadow: 'none',
      minHeight: 40,
      fontSize: 14,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isSelected ? 'black' : '',

      backgroundColor: state.isSelected ? 'rgb(189,189,189)' : 'white',
      '&:hover': {
        backgroundColor: state.isFocused ? 'rgb(219,219,219)' : 'initial'
      },
      '&:focus': {
        backgroundColor: state.isFocused ? 'white' : 'initial'
      }
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'text-blue-gray-700',
    }),
  };
  // limite da caracteres
  const limitInputSubject = 300
  const limitInputConcernedPersonName = 200

  const [ sizeSubject, setSizeSubject ] = useState<number>()
  const [ errorInputSubject, setErrorInputSubject ] = useState(false)

  const [ sizeConcernedPersonName, setSizeConcernedPersonName ] = useState<number>()
  const [ errorInputConcernedPersonName, setConcernedPersonNameErrorInput ] = useState(false)

  useEffect(() => {
    setSizeConcernedPersonName(values.concernedPersonName.length)
    if (sizeConcernedPersonName === (limitInputConcernedPersonName - 1)) {
      setConcernedPersonNameErrorInput(true)
    } else {
      setConcernedPersonNameErrorInput(false)
    }
  },[values.concernedPersonName])

  useEffect(() => {
    setSizeSubject(values.subject.length)
    if (sizeSubject === (limitInputSubject - 1)) {
      setErrorInputSubject(true)
    } else {
      setErrorInputSubject(false)
    }
  },[values.subject])

  return (
    <div>
      {getIdLaw === undefined ? (
        <>
          <Card className="w-auto h-auto mt-1 p-5 rounded-sm w-full">
            <Typography variant="h5" className="p-1 pb-6">
              Consultar
            </Typography>

            <form className="p-1">
              <div className="mb-1 gap-2 grid grid-cols-1 lg:grid-cols-1 lg:flex lg:flex-row lg:mb-5">
                <div className="basis-1/4 relative grid grid-cols-1">
                  <Select
                    styles={customStyles}
                    id="area"
                    closeMenuOnSelect={true}
                    onChange={
                      changeArea
                    }
                    options={areaList}
                    noOptionsMessage={() => 'Sem opções'}
                    placeholder=""
                    isClearable={isClearable}
                  />
                  {areaLabel != null ?
                    <label
                      htmlFor="area"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                      Área
                    </label>
                    : <label
                      htmlFor="area"
                      className="absolute text-[12px] py-[3px] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-1 scale-110 top-3 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2  peer-focus:top-2 peer-focus:scale-75 transition peer-focus:-translate-y-100 left-1">
                      Área
                    </label>
                  }
                </div>

                <div className="relative basis-1/4">
                  <Select
                    styles={customStyles}
                    id="tipo"
                    closeMenuOnSelect={true}
                    onChange={
                      changeDomainType
                    }
                    options={domainListAll}
                    noOptionsMessage={() => 'Carregando...'}
                    placeholder=""
                    isClearable={isClearable}
                  />
                  { domainLabel != null ? 
                    <label 
                      htmlFor="domain" 
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                        Tipo
                      </label> 
                    : <label
                    htmlFor="domain"
                    className="absolute text-[12px] py-[3px] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-1 scale-110 top-3 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2  peer-focus:top-2 peer-focus:scale-75 transition peer-focus:-translate-y-100 left-1">
                    Tipo
                  </label>}
                </div>

                <div className="relative basis-1/4 grid grid-cols-1">
                  <Select
                    styles={customStyles}
                    id="organizationOrigin"
                    closeMenuOnSelect={true}
                    onChange={
                      changeOrganizationOrigin
                    }
                    options={organizationOriginList}
                    noOptionsMessage={() => 'Carregando...'}
                    placeholder=""
                    isClearable={isClearable}
                  />
                  { organizationOriginLabel != null ? 
                    <label 
                      htmlFor="organizationOrigin" 
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                        Órgão de Origem
                      </label> 
                    : <label
                    htmlFor="organizationOrigin"
                    className="absolute text-[12px] py-[3px] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-1 scale-110 top-3 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2  peer-focus:top-2 peer-focus:scale-75 transition peer-focus:-translate-y-100 left-1">
                    Órgão de Origem
                  </label>}
                </div>

                <div className="basis-1/4 mb-5 sm:mb-5 lg:mb-1">
                  <Input
                    variant="outlined"
                    label="Nome do Interessado"
                    name="concernedPersonName"
                    value={values.concernedPersonName}
                    onChange={onChange}
                    color="gray"
                    crossOrigin={undefined}
                    maxLength={200}
                    error={errorInputConcernedPersonName}
                  />
                  {values.concernedPersonName.length > 0 &&
                    <div className='mt-1 mb-[-24px] text-sm text-gray-500 text-right'>
                      {sizeConcernedPersonName} caracteres de {limitInputConcernedPersonName}
                    </div>
                  }
                </div>
              </div>

              <div className="mb-1 gap-2 grid lg:flex lg:flex-row lg:mb-5">
                <div className="basis-2/7">
                  <MaskedInput
                    name="processNumber"
                    mask={maskBuildProcessNumber(values.processNumber)} 
                    maskChar=''
                    maskPlaceholder=''
                    pattern='([0-9]{8}-[0-9]{1})|([0-9]{8}/[0-9]{4})|([0-9]{5}.[0-9]{6}/[0-9]{4}-[0-9]{2})'
                    value={values.processNumber}
                    onChange={onChange}
                  >
                    <Input label="Nº Processo" crossOrigin={undefined} />
                  </MaskedInput>
                </div>
 
                <div className="basis-2/7">
                  <MaskedInput
                    mask='9999/9999' 
                    maskChar=''
                    maskPlaceholder=''
                    pattern='[0-9]{4}/[0-9]{4}'
                    name="documentNumber"
                    value={values.documentNumber}
                    onChange={onChange}
                  >
                    <Input label='Nº Documento' crossOrigin={undefined}></Input>
                  </MaskedInput>
                </div>

                <div className="basis-1/2 border-gray-300 mb-5 sm:mb-5 lg:mb-1">
                  <Input
                    label="Assunto"
                    crossOrigin={undefined}
                    name="subject"
                    value={values.subject}
                    onChange={onChange}
                    maxLength={300}
                    error={errorInputSubject}
                  />
                  {values.subject.length > 0 &&
                    <div className='mt-1 mb-[-24px] text-sm text-gray-500 text-right'>
                      {sizeSubject} caracteres de {limitInputSubject}
                    </div>
                  }
                </div>
              </div>

              <div className="mb-2 gap-2 grid lg:flex lg:flex-row">
                <div className="basis-2/7 rounded-md relative">
                  <Datepicker
                    placeholder={'Data do Documento'}
                    i18n={'pt-BR'}
                    useRange={false}
                    asSingle={true}
                    displayFormat={'DD/MM/YYYY'}
                    value={documentDateParams}
                    onChange={value => handleDateValueChange(value)}
                    popoverDirection={window.innerWidth > 750 ? 'down' : 'up'}
                    inputClassName="w-full text-sm pl-4 rounded-md min-h-[40px] border border-blue-gray-200 focus:outline-none focus:border-gray-950 focus:border-2"
                  />
                  { documentDateParams?.startDate != null ? 
                    <label 
                      htmlFor="documentDate" 
                      className="absolute !z-0 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                        Data do documento
                    </label> 
                    : <label
                        htmlFor="documentDate"
                        className="absolute !z-0 text-[13px] py-[3px] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-1 scale-110 top-3 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2  peer-focus:top-2 peer-focus:scale-75 transition peer-focus:-translate-y-100 left-1">
                        Data do documento
                      </label>}
                </div> 

                <Button onClick={searchCallback} color="teal" className="w-auto hidden sm:block">
                  Consultar
                </Button>

                <Button onClick={clearForm} color="orange" className="w-auto hidden sm:block">
                  Limpar
                </Button>
              </div>
              <div className='mt-5 mb-2 gap-2 grid sm:hidden sm:flex sm:flex-row'>
                <Button onClick={searchCallback} color="teal" className="w-auto">
                  Consultar
                </Button>

                <Button onClick={clearForm} color="orange" className="w-auto">
                  Limpar
                </Button>
              </div>
            </form>
          </Card>

          {
            loading ?
              <div className="mt-7 text-gray-500">Carregando...</div> : (
                lawsCount > 0 &&
                  <div className="mt-7">
                    <Card className="p-5 rounded-sm">
                      <h1 className="text-sm lg:text-xl font-bold">
                      Exibindo {(page-1)*itemsPerPage + 1}-{currentPage == pages ? lawsCount : currentPage*itemsPerPage} de {lawsCount} resultados 
                      </h1>
                    </Card>

                    <Card className="mt-1 p-5 rounded-sm w-full mb-3">
                      <div className="my-2 ">
                        <InternalLawsTable
                          laws={resultLaws}
                          showLaw={showLaw}
                        />

                        <div className="mt-5">
                          {pages > 1 && (
                            <Pagination
                              currentPage={currentPage}
                              lastPage={pages}
                              maxLength={window.innerWidth > 750 ? 7 : 5}
                              setCurrentPage={setPage}
                            />
                          )}
                        </div>
                      </div>
                    </Card>
                  </div>
            )
          }
        </>
        ) : (
          <Extract
            documentDate={documentDate} 
            documentNumber={documentNumber}
            processNumber={processNumber} 
            subject={subject} 
            concernedPersonName={concernedPersonName} 
            organizationOrigin={organizationOrigin?.label} 
            area={area?.label}
            domain={domain?.label} 
            idAlfresco={idAlfresco}
            setGetIDLaw={setGetIDLaw}
          />
        )
      }
    </div>
  )
};
