import React from 'react';
import { Sidebar } from '../Sidebar';
import { NavMenu } from '../NavMenu';
 
export function Layout (
  { children, open, setOpen, openSideBar, setOpenSideBar, setSearched }: 
  Readonly<{ children: React.JSX.Element, open: any, setOpen: any, openSideBar: any, setOpenSideBar: any, searched: any, setSearched: any }>
) {

  return (
    <div className='flex justify-between'>
      <div>
        <Sidebar openSideBar={openSideBar} open={open} setOpen={setOpen} setSearched={setSearched}/>
      </div>
      <div className={`${openSideBar ? 'w-[calc(100%-250px)]' : 'w-[calc(100%-50px)] sm:w-[calc(100%-84px)]'} `}>
        <NavMenu openSideBar={openSideBar} setOpenSideBar={setOpenSideBar}/>
        <div className='p-5 mt-[65px] sm:mt-[89px]'>
          {children}
        </div>
      </div>
    </div>
  );
};
