import {
  Card,
  Navbar,
  Typography,
} from '@material-tailwind/react';
import { GoLaw } from 'react-icons/go';
import { BsList } from 'react-icons/bs';
import { RxExit } from 'react-icons/rx';
import { AlertType } from '../../models/alert';
import { useAlertContext } from '../../hooks/useAlert';
import { signOut } from '../../services/service';
import { useNavigate } from 'react-router-dom';
import { clearStorage } from '../../utils/useStorage';
import api from '../../services/api';
import { useEffect } from 'react';

type NavProps = {
  openSideBar: boolean,
  setOpenSideBar: React.Dispatch<React.SetStateAction<boolean>>
}

export function NavMenu({openSideBar, setOpenSideBar}: Readonly<NavProps>) {
  const navigate = useNavigate();

  const { createAlert } = useAlertContext();

  const signoutCallback = async () => {
    try {
      await signOut();

      api.defaults.headers.Authorization = null;
      clearStorage();

      createAlert({
        message:   'Usuário deslogado com sucesso!',
        alertType: AlertType.success,
        autoHide:  true
      });

      navigate('/');
    } catch (e) {
      createAlert({
        message:   'Erro ao fazer o logout!',
        alertType: AlertType.error,
        autoHide:  true
      });
    }
  };
 
  return (
    <Navbar fullWidth className="max-w-full h-[4rem] sm:h-[5.7rem] z-10 fixed rounded-none">
      <div className="flex items-center justify-between sm:mr-12">
        <div className="flex items-center">
          <Card onClick={(e) => setOpenSideBar(!openSideBar)} className='bg-gray-50 mr-5 rounded-none cursor-pointer'>
            <BsList className="h-7 w-7 sm:h-10 sm:w-10"/>
          </Card>
          <GoLaw className="h-7 w-7  hidden sm:block sm:h-10 sm:w-10 text-blue-gray-700 mr-6"/>
          <Typography
            color="gray"
            className="flex items-center text-2xl font-bold mt-2 mb-2 hidden lg:block"
          >
            SISTEMA DE PRECEDENTES ADMINISTRATIVOS    
          </Typography>
        </div>

        <div className={`items-center gap-4 ${openSideBar ? 'mr-48' : 'mr-7'} `}>
          <div className="mr-4">
            <ul className="sm:mb-4 sm:mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
              <Typography
                as="li"
                variant="h5"
                color="gray"
                className="flex items-center sm:p-1 cursor-pointer"
                onClick={signoutCallback}
              >
                <RxExit className="h-5 w-5 mr-3"/>
                <div className='hidden sm:block'>Sair</div>
              </Typography>
            </ul>
          </div>
        </div>
      </div>
    </Navbar>
  );
}