import React, { useEffect, useState, useMemo } from 'react';
import { Alert, Card, Typography, Radio, Input, Button, Spinner } from '@material-tailwind/react';
import { FiSearch } from 'react-icons/fi';
import Select from 'react-select';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import MaskedInput from 'react-input-mask';
import { AreaDto } from '../../dtos/areaDto.model';
import { DomainDto } from '../../dtos/domainDto.model';
import { OrganizationOriginDto } from '../../dtos/organizationOriginDto.model';
import { getAreas, getDomains, getOrganizationOrigins } from '../../services/service';
import { SearchLawDto } from '../../dtos/SearchLawDto.model';
import { maskBuildProcessNumber } from '../../utils/masks'

type FormProps = {
  getSearch: ( paramsSearch: any ) => Promise<any>;
  setResultLaws: React.Dispatch<React.SetStateAction<never[]>>;
  loading: boolean;
  alertResult: boolean;
  setAlertResult: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormPublicSearchLaws = ({ getSearch, setResultLaws, loading, alertResult, setAlertResult}: FormProps) => {
  // Selects Datas
  const [ organizationOriginList, setOrganizationOriginList ] = useState<OrganizationOriginDto[]>();
  const [ domainList, setDomainList ] = useState<DomainDto[]>();
  const [ areaList, setAreaList ] = useState<AreaDto[]>();
  // Inputs
  const [ documentDateInitial, setDocumentDateInitial ] = useState<DateValueType>(null);
  const [ documentDateEnd, setDocumentDateEnd ] = useState<DateValueType>(null);
  const [ documentNumber, setDocumentNumber ] = useState<string>('');
  const [ processNumber, setProcessNumber ] = useState<string>('');
  const [ subject, setSubject ] = useState<string>('');
  const [ concernedPersonName, setConcernedPersonName ] = useState<string>('');
  // Radios
  const [ radioDomain, setRadioDomain ] = useState('')
  const [ radioAll, setRadioAll ] = useState('todos')
  // Selects
  const [ organizationOrigin, setOrganizationOrigin ] = useState<OrganizationOriginDto | null | undefined>();
  const [ area, setArea ] = useState<AreaDto | null | undefined>();
  const isClearable = useMemo(() => true, []);
  // paginação
  const page = React.useMemo(() => 1, [])
  // limite da caracteres
  const limitInputSubject = 300
  const limitInputConcernedPersonName = 200
  const [ sizeConcernedPersonName, setSizeConcernedPersonName ] = useState<number>()
  const [ sizeSubject, setSizeSubject ] = useState<number>()
  const [ errorInputSubject, setErrorInputSubject ] = useState(false)
  const [ errorInputConcernedPersonName, setConcernedPersonNameErrorInput ] = useState(false)

  const handleDateInitialValueChange = (startDate: DateValueType) => {
    setDocumentDateInitial(startDate);
  }
  const handleDateEndValueChange = (endDate: DateValueType) => {
    setDocumentDateEnd(endDate);
  }
  const handleRadioDomain = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioDomain(event.target.value)
  }
  const handleRadioAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioAll(event.target.value)
  }

  const handleSubmit = async () => {
    setResultLaws([])
    const paramsSearch = new SearchLawDto(
      radioAll,
      radioDomain,
      area?.value,
      organizationOrigin?.value,
      concernedPersonName,
      documentNumber.replace(/[^a-z0-9]/gi, ''),
      processNumber.replace(/[^a-z0-9]/gi, ''),
      subject,
      documentDateInitial?.startDate,
      documentDateEnd?.endDate,
      page
    )
    await getSearch( paramsSearch )
  }

  useEffect(() =>{
    const fetchData = async()=>{
      const OrganizationOriginList = await getOrganizationOrigins()
      const domainData = await getDomains();
      const areaData = await getAreas();

      setOrganizationOriginList(OrganizationOriginList.data.data);
      setDomainList(domainData.data.data);
      setAreaList(areaData.data.data);
    }
    fetchData();
  },[]);
  
  const [ isDateValid, setIsDateValid ] = useState(false)

  useEffect(() => {
    const isEndDateValid = documentDateEnd?.endDate != null && documentDateEnd?.endDate;
    const isStartDateValid = documentDateInitial?.startDate != null && documentDateInitial?.startDate;

    if ((isEndDateValid < isStartDateValid)) {
      setAlertResult(true)
      setIsDateValid(true)
    } else {
      setAlertResult(false)
      setIsDateValid(false)
    }
  }, [documentDateEnd, documentDateInitial]);


  useEffect(() => {
    setSizeConcernedPersonName(concernedPersonName.length)
    if (sizeConcernedPersonName === limitInputConcernedPersonName - 1) {
      setConcernedPersonNameErrorInput(true)
    } else {
      setConcernedPersonNameErrorInput(false)
    }
  },[concernedPersonName])

  useEffect(() => {
    setSizeSubject(subject.length)
    if (sizeSubject === limitInputSubject - 1) {
      setErrorInputSubject(true)
    } else {
      setErrorInputSubject(false)
    }
  },[subject])

  const customStyles = {
    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: 'bg-gray-200',
      borderRadius: '7px',
      borderWidth: state.isFocused ? '2px' : '1px',
      borderColor: state.isFocused ? 'black' : 'rgb(176 190 197)',
      '&:hover': {
        borderWidth: state.isFocused ? 2 : 1,
        borderColor: state.isFocused ? 'black' : 'rgb(176 190 197)',
      },
      border: '1px solid border-blue-gray-200',
      boxShadow: 'none',
      minHeight: 40,
      fontSize: 13,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isSelected ? 'black' : '',

      backgroundColor: state.isSelected ? 'rgb(189,189,189)' : 'white',
      '&:hover': {
        backgroundColor: state.isFocused ? 'rgb(219,219,219)' : 'initial'
      },
      '&:focus': {
        backgroundColor: state.isFocused ? 'white' : 'initial'
      }
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'text-blue-gray-700',
    }),
  };
 
  return (
    <>
      <Card className="bg-gray-444 shadow-lg rounded-none">
        <div className="flex flex-col justify-center items-center mt-6 text-1xl text-black font-bold sm:text-2xl">
          PRECEDENTES ADMINISTRATIVOS
        </div>
        <Typography className="ml-6 mt-6">
          PESQUISA
        </Typography>
        <img src={require('../../assets/line.png')} alt="Logo" className="ml-6 mr-6 mt-4 mb-2"/>
        <form className="p-6">
          <Typography className="mb-2 text-gray-900 text-sm">
            Pesquisar por
          </Typography>
          <div className="flex mb-4 ml-0 gap-1 sm:gap-10 sm:mb-6 sm:ml-4">
            {domainList?.map((domain) => (
              <Radio 
                onChange={handleRadioDomain} 
                name="radioTypeDomain" 
                key={domain.label} 
                label={
                  <div className='text-sm text-gray-900'>
                  {domain.label}
                  </div>
                } 
                value={domain.value} 
                crossOrigin={undefined} 
              />
            ))}
            <Radio 
              onChange={handleRadioAll} 
              name="radioTypeDomain" 
              label={
                <div className='text-sm text-gray-900'>
                Todos
                </div>
              } 
              value={'todos'} 
              defaultChecked 
              crossOrigin={undefined} 
            />
          </div>
          <div className="mb-2 grid gap-2 sm:flex sm:flex-row sm:mb-10">
            <div className="basis-1/2 relative grid grid-cols-1">
              <Select
                id="area"
                className=""
                styles={customStyles}
                value={area}
                closeMenuOnSelect={true}
                onChange={(e)=> setArea(e)}
                options={areaList}
                noOptionsMessage={() => 'Sem opções'}
                placeholder="Área"
                isClearable={isClearable}
              />
              { area != null ? 
                <label 
                  htmlFor="area" 
                  className="absolute text-sm text-blue-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-444 dark:bg-gray-200 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2">
                    Área
                  </label> 
                : <label
                htmlFor="area"
                className="absolute text-[13px] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-1 scale-110 top-3 z-10 origin-[0] bg-gray-444 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2  peer-focus:top-2 peer-focus:scale-75 transition peer-focus:-translate-y-100 left-1">
                Área
              </label>}
            </div>
            <div className="basis-1/2 relative grid grid-cols-1">
              <Select
                id="domain"
                className=""
                styles={customStyles}
                closeMenuOnSelect={true}
                value={organizationOrigin}
                options={organizationOriginList}
                onChange={(e)=> setOrganizationOrigin(e)}
                placeholder=""
                noOptionsMessage={() => 'Sem opções'}
                isClearable={isClearable}
              />
              { organizationOrigin != null ? 
                <label 
                  htmlFor="domain" 
                  className="absolute text-sm text-blue-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-gray-444 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2">
                    Órgão de Origem
                  </label> 
                : <label
                htmlFor="organizationOrigin"
                className="absolute text-[13px] text-blue-gray-500 dark:text-gray-400 duration-300 transform -translate-y-1 scale-110 top-3 origin-[0] bg-gray-444 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2  peer-focus:top-2 peer-focus:scale-75 transition peer-focus:-translate-y-100 left-1">
                Órgão de Origem
              </label>}
            </div>
            <div className="basis-1/2">
              <Input 
                value={concernedPersonName} 
                onChange={(e) =>setConcernedPersonName(e.target.value)}
                variant="outlined" 
                label="Nome do Interessado" 
                color="gray" 
                error={errorInputConcernedPersonName}
                maxLength={200}
                crossOrigin={undefined} 
              />
              {concernedPersonName.length > 0 &&
                <div className='mt-1 mb-[-24px] text-sm text-gray-500 text-right'>
                  {sizeConcernedPersonName} caracteres de {limitInputConcernedPersonName}
                </div>
              }
            </div>
          </div>
          <div className="mb-3 grid gap-2 sm:flex sm:flex-row sm:mb-8">
            <div className="basis-1/2">
              <MaskedInput 
                id='processNumber'
                value={processNumber} 
                onChange={(e) => setProcessNumber(e.target.value)}
                mask={maskBuildProcessNumber(processNumber)} 
                maskChar=''
                maskPlaceholder=''
                pattern='([0-9]{8}-[0-9]{1})|([0-9]{8}/[0-9]{4})|([0-9]{5}.[0-9]{6}/[0-9]{4}-[0-9]{2})'
                >
                  <Input label='Nº Processo' crossOrigin={undefined}></Input>
              </MaskedInput>
            </div>
            <div className="basis-1/2">
              <MaskedInput 
                id='documentNumber'
                value={documentNumber} 
                onChange={(e) => setDocumentNumber(e.target.value)}
                mask='9999/9999' 
                maskChar=''
                maskPlaceholder=''
                pattern='[0-9]{4}/[0-9]{4}'
                >
                  <Input label='Nº Documento' crossOrigin={undefined}></Input>
              </MaskedInput>
            </div>
            <div className="basis-1/2">
              <Input 
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                label="Assunto" 
                crossOrigin={undefined} 
                error={errorInputSubject}
                maxLength={300}
              />
              {subject.length > 0 &&
                <div className='mt-1 mb-[-24px] text-sm text-gray-500 text-right'>
                  {sizeSubject} caracteres de {limitInputSubject}
                </div>
              }
            </div>
          </div>
          <Typography variant='small' className="text-gray-900 mb-1">
            Data do Documento
          </Typography>
          <div className="mb-5 grid gap-2 sm:flex sm:flex-row">
            <div className="basis-1/5">
              <Datepicker 
                placeholder={'Inicial'} 
                i18n={'pt-BR'} 
                useRange={false} 
                asSingle={true} 
                displayFormat={'DD/MM/YYYY'} 
                value={documentDateInitial} 
                primaryColor={'green'}
                onChange={value => handleDateInitialValueChange(value)}
                inputClassName="w-full bg-gray-444 text-sm pl-4 rounded-md min-h-[40px] border border-blue-gray-200 focus:outline-none focus:border-gray-950 focus:border-2"
              /> 
            </div>
            <div className="basis-1/5">
              <Datepicker 
                placeholder={'Final'} 
                i18n={'pt-BR'} 
                useRange={false} 
                asSingle={true} 
                displayFormat={'DD/MM/YYYY'} 
                value={documentDateEnd} 
                primaryColor={'green'}
                onChange={value => handleDateEndValueChange(value)}
                inputClassName="w-full bg-gray-444 text-sm pl-4 rounded-md min-h-[40px] border border-blue-gray-200 focus:outline-none focus:border-gray-950 focus:border-2"
              /> 
            </div>
          </div>
          <div className="mt-6 flex flex-col items-center sm:mt-12">
            <Button onClick={handleSubmit} disabled={isDateValid} type="button" size="md" className="flex items-center gap-4 bg-gray-555">
              {loading? (
                <Spinner className="h-4 w-4 ml-8 mr-8 text-white"/>
              ) : (
                <>
                  <FiSearch className='h-4 w-4'/>
                  BUSCAR
                </>
              )}
            </Button>
          </div>
        </form>
      </Card>
      <div className='w-auto mt-5 flex items-center'>
        <Alert
          variant="gradient"
          open={alertResult}
          action={
            <Button
              variant="text"
              color="red"
              size="sm"
              className=""
              onClick={() => setAlertResult(false)}
            >
              Fechar
            </Button>
          }
        >
          {isDateValid ? 'Data final é invalida!' : 'Nenhum registro encontrado!'}
        </Alert>
      </div>
    </>
  )
}

export default FormPublicSearchLaws;
