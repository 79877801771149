import { DeleteLawDto } from '../dtos/deleteLaw.model';
import { LawDto } from '../dtos/lawDto.model';
import axios from './api';

const PATH_API = process.env.REACT_APP_PUBLIC_API_BASE_URL;

export function signIn (userInfo: {
  user:     string
  password: string
}) {
  return axios.post(`${PATH_API}/auth/sign_in`, userInfo);
};

export function signOut () {
  return axios.delete(`${PATH_API}/auth/sign_out`);
};

export function getOrganizationOrigins() {
  return axios.get(`${PATH_API}/organization_origins`)
};

export function getDomains() {
  return axios.get(`${PATH_API}/domains`)
};

export function getAreas() {
  return axios.get(`${PATH_API}/areas`)
};

export function getLawtypes () {
  return axios.get(`${PATH_API}/law_types`)
};

export function createLaw(law:LawDto) {
  const params = {
    'document_date': law.documentDate,
    'concerned_person_name': law.concernedPersonName,
    'document_number': law.documentNumber,
    'process_number': law.processNumber,
    'samba_user_id': law.sambaUserId,
    'user_name_samba': law.sambaUserName,
    'user_email_samba': law.sambaUserMail,
    'file': law.file,
    'area_id': law.area?.value,
    'domain_id': law.domain?.value,
    'subject': law.subject,
    'organization_origin_id': law.organizationOrigin?.value
  }

  return axios.post(`${PATH_API}/laws`,params, {headers: { 'Content-Type' : 'multipart/form-data' }})
};

export function getLawForUpdate(id:any){
  return axios.get(`${PATH_API}/laws/${id}`)
}

export  function updateLaw(law:LawDto) {
  const params = {
      'document_date': law.documentDate,
      'concerned_person_name': law.concernedPersonName,
      'document_number': law.documentNumber,
      'process_number': law.processNumber,
      'samba_user_id': law.sambaUserId,
      'user_name_samba': law.sambaUserName,
      'user_email_samba': law.sambaUserMail,
      'file': law.file,
      'area_id': law.area?.value,
      'domain_id': law.domain?.value,
      'subject': law.subject,
      'organization_origin_id': law.organizationOrigin?.value
  }
  return axios.put(`${PATH_API}/laws/${law.id}`, params, {headers: { 'Content-Type' : 'multipart/form-data' }})
};

export function getShowLaw(id:any) {
  return axios.get(`${PATH_API}/laws/${id}`)
}

export function deleteLaw(law:DeleteLawDto) {
  const params = {
    'id': law.idLaw,
    'user_id_samba': law.userID,
    'user_user_samba': law.userName,
    'user_email_samba': law.userEmail,
  }
  return axios.post(`${PATH_API}/laws/internal/delete`, params)
}

export function searchPublicLaw(paramsSearch: { radioAll: any; radioDomain: any; area: any; organizationOrigin: any; concernedPersonName: any; documentNumber: any; processNumber: any; subject: any; documentDateInitial: any; documentDateEnd: any; page: any; }) {
  return axios.get(
    `${PATH_API}/laws/public/search?all=${paramsSearch.radioAll}&domain_id=${paramsSearch.radioDomain}&area_id=${paramsSearch.area}&organization_origin_id=${paramsSearch.organizationOrigin}&concerned_person_name=${paramsSearch.concernedPersonName}&document_number=${paramsSearch.documentNumber}&process_number=${paramsSearch.processNumber}&subject=${paramsSearch.subject}&document_date_initial=${paramsSearch.documentDateInitial}&document_date_end=${paramsSearch.documentDateEnd}&page=${paramsSearch.page}`
  );
};

export function getPdfLaw(id_alfresco: string) {
  return axios.get(
    `${PATH_API}/laws/download?id_alfresco=${id_alfresco}`, { responseType: 'blob' }
  );
}

export function getInternalLaws(
  page: any,
  areaId: any,
  domainId: any,
  organizationOriginId: any,
  concernedPersonName: any,
  documentNumber: any,
  processNumber: any,
  subject: any,
  documentDate: any
) {
  return axios.get(
    `${PATH_API}/laws/internal/search?page=${page}&domain_id=${domainId}&area_id=${areaId}&organization_origin_id=${organizationOriginId}&concerned_person_name=${concernedPersonName}&document_number=${documentNumber.replace(/[^a-z0-9]/gi, '')}&process_number=${processNumber.replace(/[^a-z0-9]/gi, '')}&subject=${subject}&document_date_initial=${documentDate}`
  );
};
