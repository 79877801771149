import { useCallback, useState } from 'react';
import { AlertType } from '../models/alert';
import { getInternalLaws } from '../services/service';
import { useAlertContext } from './useAlert';

export function useSearchInternalLaws() {
  const { createAlert } = useAlertContext();

  const [ resultLaws, setResultLaws ] = useState([]);
  const [ alertResult, setAlertResult ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ currentPage, setCurrentPage ] = useState<number>(1);
  const [ itemsPerPage, setItemsPerPage ] = useState<number>(0);
  const [ totalPage, setTotalPage ] = useState<number>(0);
  const [ lawsCount, setLawsCount ] = useState(0);

  const getLaws = useCallback(
    async (
      values: any,
      page: number,
    ) => {
      interalLawsConsumer(
        page,
        values.domainId,
        values.areaId,
        values.organizationOriginId,
        values.concernedPersonName,
        values.documentNumber,
        values.processNumber,
        values.subject,
        values.documentDate
      );
    }, []
  );

  const interalLawsConsumer = async (
    page: number,
    domainId: any,
    areaId: any,
    organizationOriginId: any,
    concernedPersonName: any,
    documentNumber: any,
    processNumber: any,
    subject: any,
    documentDate: any,
  ) => {
    try {
      setLoading(true);

      const response = await getInternalLaws(
        page,
        areaId,
        domainId,
        organizationOriginId,
        concernedPersonName,
        documentNumber,
        processNumber,
        subject,
        documentDate,
      );

      if (response.data.laws_count > 0) {
        createAlert({
          alertType: AlertType.success,
          message: 'Precedentes listados com sucesso!',
          autoHide: true
        });

        setResultLaws(response.data.data);
        setCurrentPage(response.data.current_page);
        setItemsPerPage(response.data.items_per_page);
        setTotalPage(response.data.total_pages);
        setLawsCount(response.data.laws_count);
      }

      if (response.data.laws_count === 0) {
        setResultLaws([]);
        setCurrentPage(1);
        setItemsPerPage(0);
        setTotalPage(0);
        setLawsCount(0);

        createAlert({
          alertType: AlertType.error,
          message: 'Sem registros para essa consulta!',
          autoHide: true
        });
      }
    } catch {
      createAlert({
        alertType: AlertType.error,
        message: 'Não foi possível realizar a consulta.',
        autoHide: true
      });
    } finally {
      setLoading(false);
    };
  };

  return { loading, resultLaws, getLaws, setResultLaws, alertResult, setAlertResult, lawsCount, itemsPerPage, totalPage, currentPage, setLawsCount };
}
