export class DeleteLawDto {
  idLaw?: number;
  userID?: string;
  userName?: string;
  userEmail?: string;
  constructor(idLaw: number, userID: string | undefined, userName: string | undefined, userEmail: string | undefined
    ){
    this.idLaw = idLaw;
    this.userID = userID;
    this.userName = userName;
    this.userEmail =  userEmail;
  }
}