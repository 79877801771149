import { Button, Typography } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlertContext } from '../../hooks/useAlert';
import { AlertType } from '../../models/alert';
import { signIn } from '../../services/service';
import { useForm } from '../../utils/useForm';
import { getUserToken, setUserStorage } from '../../utils/useStorage';

export const Login = () => {
  const navigate = useNavigate();

  const { createAlert } = useAlertContext();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserToken() && navigate('/consulta');
  }, []);

  const loginCallback = async () => {
    try {      
      setLoading(true);

      const response: any = await signIn(values);

      if (response.status === 200) {
        setUserStorage(response.data.data.token);

        createAlert({
          message:   'Login realizado com sucesso!',
          alertType: AlertType.success,
          autoHide:  true
        });

        navigate('/consulta');
      } else {
        createAlert({
          message:   'Usuário ou senha inválidos.',
          alertType: AlertType.error
        });
      }
    } catch (e) {
      createAlert({
        message:   'Usuário ou senha inválidos.',
        alertType: AlertType.error
      });
    } finally {
      setLoading(false);
    };
  };

  const initialState = {
    user: '',
    password: ''
  };

  const { onChange, onSubmit, values }: any = useForm(
    loginCallback,
    initialState
  );

  return (
    <div className="bg-gray-0 h-screen flex flex-col justify-center items-center">
      <div className="mx-auto w-auto">
        <img
          src={require('../../assets/logo-pge.png')}
          alt="Logo"
          className="w-52 mb-3 justify-center"
        />
      </div>

      <Typography variant="h4" color="gray" className="flex p-7 text-center">
        SISTEMA DE PRECEDENTES ADMINISTRATIVOS    
      </Typography>

      <Typography variant="h5" className="flex p-2 mb-10 text-blue-gray-900">
        Bem vindo!
      </Typography>

      {
        loading ? (
          <>Carregando...</>
        ) : (
          <form onSubmit={onSubmit}>
            <div className="mb-4 w-72 flex flex-col gap-1">
              <p className="text-gray-600 font-bold">Login</p>
              
              <input
                type="text"
                name="user"
                onChange={onChange}
                placeholder="Login"
                className="border-2 border-gray-300 rounded-md p-1"
              />
            </div>
    
            <div className="mb-4 flex flex-col gap-1">
              <p className="text-gray-600 font-bold">Senha</p>
    
              <input
                type="password"
                name="password"
                onChange={onChange}
                placeholder="Senha"
                className="border-2 border-gray-300 rounded-md p-1"
              />
            </div>
    
            <Button type="submit" className="mt-8 mb-12" color="teal" fullWidth>
              ENTRAR
            </Button>
          </form>
        )
      }
    </div>
  );
};
