import { useContext } from 'react';
import { AlertContext, AlertContextType } from '../contexts/alert';

export const useAlertContext = (): AlertContextType => {
  const context = useContext(AlertContext);

  if (context === undefined)
    throw new Error('Erro na geração dos alertas.');

  return context;
};
