import { useSearchPublicLaws } from '../../hooks/useSearchPublicLaws';
import FormPublicSearchLaws from '../../components/FormPublicSearchLaws';
import ResultPublicSearchLaws from '../../components/ResultPublicSearchLaws';


export function PublicSearchLaws() {
  const { resultLaws, getLaws, setResultLaws, loading, alertResult, setAlertResult, searchParams, lawsCount, itemsPerPage, totalPage, currentPage } = useSearchPublicLaws()

  return (
    <div className="bg-gray-0 p-5 flex flex-col justify-center items-center">
      {resultLaws.length === 0 ? (
        <FormPublicSearchLaws 
          getSearch={getLaws} 
          setResultLaws={setResultLaws} 
          loading={loading}
          alertResult={alertResult}
          setAlertResult={setAlertResult}
        />
        ) : (
        <ResultPublicSearchLaws 
          listLaws={resultLaws} 
          setResultLaws={setResultLaws} 
          getSearch={getLaws} 
          searchParams={searchParams} 
          lawsCount={lawsCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          totalPage={totalPage}
        />
        )
      }      
    </div>
  )
}