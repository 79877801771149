import jwt_decode from 'jwt-decode';

import { useState } from 'react';
import { UserType } from '../models/user';

export const setUserStorage = (token: string) => {
  localStorage.setItem('token', JSON.stringify(token));

  const decode: { data: UserType } = jwt_decode(token);

  const { user_id, user_name, user_mail } = decode.data;

  localStorage.setItem('user_id', user_id);
  localStorage.setItem('user_name', user_name);
  localStorage.setItem('user_mail', user_mail);

  return true;
};

export const getUserStorage = () => {
  const user_id   = localStorage.getItem('user_id');
  const user_name = localStorage.getItem('user_name');
  const user_mail = localStorage.getItem('user_mail');

  const [userStatement, _] = useState<UserType>({
    user_id:   user_id   ? user_id   : '',
    user_name: user_name ? user_name : '',
    user_mail: user_mail ? user_mail : ''
  });

  return userStatement;
};

export const getUserToken = () => localStorage.getItem('token');

export const clearStorage = () => localStorage.clear();
