import { AreaDto } from './areaDto.model';
import { DomainDto } from './domainDto.model';
import { OrganizationOriginDto } from './organizationOriginDto.model';

export class LawDto {
    id?:number;
    documentDate?: Date;
    concernedPersonName?: string;
    subject?: string;
    documentNumber?: string;
    processNumber?: string;
    sambaUserId?: number;
    sambaUserMail?:string | null;
    sambaUserName?:string | null;
    file?: File;
    area?: AreaDto;
    domain?: DomainDto;
    organizationOrigin?: OrganizationOriginDto;

    constructor(id: number,domain: DomainDto | undefined, documentDate: Date | undefined,
        organizationOrigin: OrganizationOriginDto | undefined, concernedPersonName: string | undefined, 
        processNumber: string | undefined, area: AreaDto | undefined, documentNumber: string | undefined,
        subject: string | undefined, file: File | undefined, sambaUserId: number | undefined, sambaUserMail: string | null, sambaUserName: string | null
        ){
        this.organizationOrigin = organizationOrigin;
        this.domain = domain;
        this.documentDate =  documentDate;
        this.concernedPersonName = concernedPersonName;
        this.processNumber = processNumber;
        this.area = area;
        this.documentNumber = documentNumber;
        this.subject =  subject;
        this.file = file;
        this.sambaUserId = sambaUserId;
        this.sambaUserMail = sambaUserMail;
        this.sambaUserName = sambaUserName;
        this.id = id;
    }

}