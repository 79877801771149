import { Typography, Card, Input, Button, Spinner } from '@material-tailwind/react';
import React, { FC, useEffect, useState } from 'react';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import { OrganizationOriginDto } from '../../dtos/organizationOriginDto.model';
import { getOrganizationOrigins, getDomains, getAreas, createLaw, getLawForUpdate, updateLaw } from '../../services/service';
import Select from 'react-select';
import { AreaDto } from '../../dtos/areaDto.model';
import { DomainDto } from '../../dtos/domainDto.model';
import { LawDto } from '../../dtos/lawDto.model';
import MaskedInput from 'react-input-mask';
import { maskBuildProcessNumber } from '../../utils/masks'
import { useAlertContext } from '../../hooks/useAlert';
import { AlertType } from '../../models/alert';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router'

type SearchProps = {
  setSearched: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: any
}

const FormRegistration: FC<any> = ({ setSearched, setOpen }: SearchProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [organizationOriginList, setOrganizationOriginList ] = useState<OrganizationOriginDto[]>();
  const [domainList, setDomainList ] = useState<DomainDto[]>();
  const [ areaList, setAreaList ] = useState<AreaDto[]>();

  const [documentDate, setDocumentDate] = useState<DateValueType>(null);
  const [documentNumberWithMask, setDocumentNumberWithMask] = useState('');
  const [processNumberWithMask, setProcessNumberWithMask] = useState('');
  const [subject, setSubject] = useState('');
  const [concernedPersonName, setConcernedPersonName] = useState('');

  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileName, setFileName] = useState('');

  const [organizationOrigin, setOrganizationOrigin] = useState<OrganizationOriginDto | null | undefined>();
  const [area, setArea] = useState<AreaDto | null | undefined>();
  const [domain, setDomain] = useState<DomainDto | null | undefined >();

  const { createAlert } = useAlertContext()
  React.useEffect(() => { handleEditInit(id) }, [organizationOriginList, domainList, areaList])
  const [ loading, setLoading ] = useState<boolean>()
  // limite da caracteres
  const limitInputSubject = 5000
  const limitInputConcernedPersonName = 200
  const [ sizeConcernedPersonName, setSizeConcernedPersonName ] = useState<number>()
  const [ sizeSubject, setSizeSubject ] = useState<number>()
  const [ errorInputSubject, setErrorInputSubject ] = useState(false)
  const [ errorInputConcernedPersonName, setConcernedPersonNameErrorInput ] = useState<boolean>(false)

  useEffect(() => {
    setSizeConcernedPersonName(concernedPersonName.length)
    if (sizeConcernedPersonName === (limitInputConcernedPersonName - 1)) {
      setConcernedPersonNameErrorInput(true)
    } else {
      setConcernedPersonNameErrorInput(false)
    }
  },[concernedPersonName])

  useEffect(() => {
    setSizeSubject(subject.length)
    if (sizeSubject === (limitInputSubject - 1)) {
      setErrorInputSubject(true)
    } else {
      setErrorInputSubject(false)
    }
  },[subject])

  useEffect(() =>{
    const fetchData = async()=>{
      const data = await getOrganizationOrigins()
      const domainData = await getDomains();
      const areaData = await getAreas();
      setFileName('a')
      setOrganizationOriginList(data.data.data);
      setDomainList(domainData.data.data);
      setAreaList(areaData.data.data);

      document.getElementById('documentDate')?.setAttribute('required','true');

    }
    fetchData();
  },[id]);

  const handleDateValueChange = (newValue: DateValueType) => {
    setDocumentDate(newValue);
  }

  const handleEditInit = async (id:any) =>{
    if (id) {
      try {
        setLoading(true)
        const data = (await getLawForUpdate(id)).data;
        setSubject(data.subject)
        setArea(areaList?.find(area => area.value == data.area_id))
        setDomain(domainList?.find(domain => domain.value == data.domain_id))
        setOrganizationOrigin(organizationOriginList?.find(organization => organization.value == data.organization_origin_id))
        setConcernedPersonName(data.concerned_person_name)
        setDocumentDate({startDate:data.document_date, endDate: data.document_date})
        setDocumentNumberWithMask(String(data.document_number));
        setProcessNumberWithMask(String(data.process_number))
        setLoading(false)
      } catch (error:any) {
        createAlert({
          message: error,
          alertType: AlertType.error,
          autoHide: true
        });
      } 
    }else{
      resetForm();
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    const newLaw: LawDto = new LawDto(
      Number(id),
      domain!,
      // eslint-disable-next-line
      new Date(documentDate?.startDate!),
      organizationOrigin!,
      concernedPersonName,
      processNumberWithMask.replace(/[^a-z0-9]/gi, ''),
      area!,
      documentNumberWithMask.replace(/[^a-z0-9]/gi, ''),
      subject,
      file,
      Number(localStorage.getItem('user_id')),
      localStorage.getItem('user_mail'),
      localStorage.getItem('user_name')
    );
    try {
      setLoading(true)
      let response:any;
      if(id){
        response = await updateLaw(newLaw);
      }else{
        response = await createLaw(newLaw);
      }
      createAlert({
        message: response.data.message,
        alertType: AlertType.success,
        autoHide: true
      });
      resetForm();
      navigate({pathname: '/consulta'}) // , search: '?default-search=true'
      setOpen(1)
      setSearched(true)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      let errorMessages = '';
      error.response.data?.message.map((message: any) =>{
        errorMessages = errorMessages.concat(message + ' \n ');
      });
      createAlert({
        message: errorMessages,
        alertType: AlertType.error,
        autoHide: true
      });
    }
  }

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileSize = event?.target?.files?.[0]?.size
    const maxFileSizeAllowed = 52428800;
    if( fileSize != undefined && fileSize < maxFileSizeAllowed){
      setFile(event?.target?.files?.[0]);
    } else {
      createAlert({
        message: 'Arquivo deve ter no máximo 50MB',
        alertType: AlertType.danger,
        autoHide: true
      });
      setFile(undefined);
      setFileName('a')
      resetFileField();
    }
  };

  const resetFileField = () =>{
    const form = document.querySelector('form');
    form?.reset();
  }

  const redirectToSearch = () =>{
    navigate({pathname: '/consulta'})
    setOpen(1)
    setSearched(false)
  }

  const resetForm = () => {
    setConcernedPersonName('');
    const resetDate: DateValueType = {startDate: null, endDate: null}
    handleDateValueChange(resetDate);
    setDocumentDate(resetDate);
    setProcessNumberWithMask('');
    setDocumentNumberWithMask('');
    setFile(new File([] ,'',undefined));
    setFileName('');
    setArea(null);
    setDomain(null);
    setOrganizationOrigin(null);
    setSubject('');
    resetFileField();
  }

  const customStyles = {
    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: 'bg-gray-200',
      borderRadius: '7px',
      borderWidth: state.isFocused ? '2px' : '1px',
      borderColor: state.isFocused ? 'black' : 'rgb(176 190 197)',
      '&:hover': {
        borderWidth: state.isFocused ? 2 : 1,
        borderColor: state.isFocused ? 'black' : 'rgb(176 190 197)',
      },
      border: '1px solid border-blue-gray-200',
      boxShadow: 'none',
      minHeight: 40,
      fontSize: 13
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isSelected ? 'black' : '',
      font: 'bold',
      backgroundColor: state.isSelected ? 'rgb(189,189,189)' : 'white',
      '&:hover': {
        backgroundColor: state.isFocused ? 'rgb(219,219,219)' : 'initial'
      },
      '&:focus': {
        backgroundColor: state.isFocused ? 'white' : 'initial'
      }
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'text-blue-gray-700'
    }),
  }; 

 

  return (
  <div>
      <Card className="w-auto h-auto mt-1 p-5 rounded-sm w-full">
        <Typography variant="h5" className="p-1 pb-6">
          {id ? 'Editar': 'Cadastrar'}
        </Typography>
        <form className="p-1" onSubmit={handleSubmit}>
          <div className="mb-1 gap-2 grid sm:grid-cols-1 lg:flex lg:flex-row lg:mb-5">
            <div className="basis-1/4 relative grid grid-cols-1">
              <Select
                id='area'
                className='placeholder-red-300'
                value={area}
                closeMenuOnSelect={true}
                onChange={(event)=> setArea(event)}
                options={areaList}
                noOptionsMessage={() => 'Sem opções'}
                placeholder='Área'
                required={true}
                styles={customStyles}
              />
              
              {area != null ?
                <label
                  htmlFor="area"
                  className="required absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                  Área
                </label>
                : <label
                  htmlFor="area"
                  className="required absolute text-[13px] py-[3px] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-1 scale-110 top-3 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2  peer-focus:top-2 peer-focus:scale-75 transition peer-focus:-translate-y-100 left-1">
                  Área
                </label>}
            </div>
            <div className="basis-1/4 relative">
              <Select
                id='domain'
                className=''
                closeMenuOnSelect={true}
                value={domain}
                options={domainList}
                onChange={(event) => setDomain(event)}
                placeholder='Tipo*'
                noOptionsMessage={() => 'Sem opções'}
                required={true}
                styles={customStyles}
              />
              { domain != null ? 
                <label 
                  htmlFor="domain" 
                  className="required absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                    Tipo
                  </label> 
                : <label
                htmlFor="domain"
                className="required absolute text-[13px] py-[3px] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-1 scale-110 top-3 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2  peer-focus:top-2 peer-focus:scale-75 transition peer-focus:-translate-y-100 left-1">
                Tipo
              </label>}
            </div>
            <div className="basis-1/4 relative grid grid-cols-1">
              <Select
                id='organizationOrigin'
                className=''
                closeMenuOnSelect={true}
                value={organizationOrigin}
                options={organizationOriginList}
                onChange={(event)=> setOrganizationOrigin(event)}
                placeholder='Órgão de Origem'
                noOptionsMessage={() => 'Sem opções'}
                required={true}
                styles={customStyles}
              />
              { organizationOrigin != null ? 
                <label 
                  htmlFor="organizationOrigin" 
                  className="required absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                    Órgão de Origem
                  </label> 
                : <label
                htmlFor="organizationOrigin"
                className="required absolute text-[13px] py-[3px] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-1 scale-110 top-3 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 transition peer-focus:-translate-y-100 left-1">
                Órgão de Origem
              </label>}
            </div>
            <div className="basis-1/4 mb-5 sm:mb-1">
              <Input 
                id='concernedPersonName'
                value={concernedPersonName} 
                onChange={(event) =>setConcernedPersonName(event.target.value)}
                variant="outlined" 
                label='Nome do Interessado' 
                color='gray'
                crossOrigin={undefined} 
                type='text'
                required={true}
                maxLength={200}
                error={errorInputConcernedPersonName}
              />
              {concernedPersonName.length > 0 &&
                <div className='mt-1 mb-[-24px] text-sm text-gray-500 text-right'>
                  {sizeConcernedPersonName} caracteres de {limitInputConcernedPersonName}
                </div>
              }
            </div>
          </div>
          <div className="mb-1 gap-2 grid lg:flex lg:flex-row lg:mb-5">
            <div className="basis-2/7">
              <MaskedInput 
                id='processNumber'
                value={processNumberWithMask} 
                onChange={(event) => setProcessNumberWithMask(event.target.value)}
                required={true}
                mask={maskBuildProcessNumber(processNumberWithMask)} 
                maskChar=''
                maskPlaceholder=''
                pattern='([0-9]{8}-[0-9]{1})|([0-9]{8}/[0-9]{4})|([0-9]{5}.[0-9]{6}/[0-9]{4}-[0-9]{2})'
                >
                  <Input label='Nº Processo' crossOrigin={undefined}></Input>
              </MaskedInput>
            </div>
            <div className="basis-2/7">
              <MaskedInput 
                id='documentNumber'
                value={documentNumberWithMask} 
                onChange={(event) => setDocumentNumberWithMask(event.target.value)}
                mask='9999/9999' 
                maskChar=''
                maskPlaceholder=''
                pattern='[0-9]{4}/[0-9]{4}'
                >
                  <Input label='Nº Documento' crossOrigin={undefined}></Input>
              </MaskedInput>
            </div>
            <div className="basis-1/2 border-gray-300 mb-5 sm:mb-1">
              <Input 
                id='subject'
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
                label='Assunto' 
                crossOrigin={undefined} 
                required={true}
                maxLength={5000}
                error={errorInputSubject}
              />
              {subject.length > 0 &&
                <div className='mt-1 mb-[-24px] text-sm text-gray-500 text-right'>
                  {sizeSubject} caracteres de {limitInputSubject}
                </div>
              }
            </div>
          </div>
          <div className="mb-2 gap-2 grid lg:flex lg:flex-row lg:mb-5">
            <div className="basis-2/7 rounded-md border border-gray-300 relative">
            <Datepicker
              inputId='documentDate' 
              placeholder={''} 
              i18n={'pt-BR'} 
              useRange={false} 
              asSingle={true} 
              displayFormat={'DD/MM/YYYY'} 
              value={documentDate} 
              primaryColor={'green'}
              onChange={value => handleDateValueChange(value)} 
            /> 
            { documentDate?.startDate != null ? 
                <label 
                  htmlFor="documentDate" 
                  className="required absolute !z-0 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                    Data do documento
                  </label> 
                : <label
                htmlFor="documentDate"
                className="required absolute !z-0 text-[13px] py-[3px] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-1 scale-110 top-3 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2  peer-focus:top-2 peer-focus:scale-75 transition peer-focus:-translate-y-100 left-1">
                Data do documento
              </label>}
            </div>
            <input type="file"
              id='file'
              onChange={event => handleFileSelect(event)}
              className="block text-sm text-slate-500 rounded-md border border-gray-300
              file:h-full
              file:mr-4 file:py-2 file:px-3
              file:rounded-md file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100
              basis-2/7"
              required={id ? false : true}
              accept='.pdf'
              name={fileName}
            />
            <Button type="submit" color="teal" className="hidden sm:block">
              {loading? (
                <Spinner className="h-4 w-4 ml-8 mr-8 text-white"/>
              ) : (
                <>
                  {id ? 'Alterar': 'Cadastrar'}
                </>
              )}
            </Button>
            <Button onClick={redirectToSearch} color="orange" className="hidden sm:block">
              Cancelar
            </Button>
          </div>
          <div className="mt-5 mb-2 gap-2 grid sm:hidden sm:flex sm:flex-row">
            <Button type="submit" color="teal" className="">
              {loading? (
                <Spinner className="h-4 w-4 ml-8 mr-8 text-white"/>
              ) : (
                <>
                  {id ? 'Alterar': 'Cadastrar'}
                </>
              )}
            </Button>
            <Button onClick={redirectToSearch} color="orange" className="">
              Cancelar
            </Button>
          </div>
        </form>
      </Card>
    </div>
);
}

export default FormRegistration;
