import { useCallback, useState } from 'react';
import { searchPublicLaw } from '../services/service';

export function useSearchPublicLaws() {
  const [ resultLaws, setResultLaws ] = useState([])
  const [ alertResult, setAlertResult ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false);

  const [ searchParams, setSearchParams ] = useState()

  const [ currentPage, setCurrentPage ] = useState<number>(1)
  const [ itemsPerPage, setItemsPerPage ] = useState<number>(0)
  const [ totalPage, setTotalPages ] = useState<number>(0)
  const [ lawsCount, setLawsCount ] = useState()

  const getLaws = useCallback(
    async (paramsSearch: any) => {
      setSearchParams(paramsSearch)
      asyncGetLaws(paramsSearch)
    },[]
  )

  async function asyncGetLaws(paramsSearch: any) {
    try {
      setLoading(true);
      const response = await searchPublicLaw(
        paramsSearch
      )

      if ( response.status === 200 && response.data.laws_count > 0 ) {
        const sortedLaws = response.data.laws_list.sort((a: { document_date: string }, b: { document_date: string }) => {
          return new Date(b.document_date).getTime() - new Date(a.document_date).getTime();
        });
        setResultLaws(sortedLaws)
        setCurrentPage(response.data.current_page)
        setItemsPerPage(response.data.items_per_page)
        setTotalPages(response.data.total_pages)
        setLawsCount(response.data.laws_count)
      } else {
        setAlertResult(true)
      }
      setLoading(false); 
    } catch (error) {
      return new Error('Deu ruim!')
    }

  }

  return { loading, resultLaws, getLaws, setResultLaws, alertResult, setAlertResult, searchParams, lawsCount, itemsPerPage, totalPage, currentPage }
}
