import { AreaDto } from './areaDto.model';
import { OrganizationOriginDto } from './organizationOriginDto.model';
import { DateType } from 'react-tailwindcss-datepicker';

export class SearchLawDto {
  radioAll?: string;
  radioDomain?: string;
  area?: AreaDto | number;
  organizationOrigin?: OrganizationOriginDto | number;
  concernedPersonName?: string;
  documentNumber?: string;
  processNumber?: string;
  subject?: string;
  documentDateInitial?: DateType; 
  documentDateEnd?: DateType;
  page: number;

  constructor(
    radioAll: string | undefined, 
    radioDomain: string | undefined, 
    area: AreaDto | number | undefined, 
    organizationOrigin: OrganizationOriginDto | number | undefined, 
    concernedPersonName: string | undefined, 
    documentNumber: string | undefined,
    processNumber: string | undefined, 
    subject: string | undefined, 
    documentDateInitial: DateType | undefined,
    documentDateEnd: DateType | undefined, 
    page: number
    ){
    this.radioAll =radioAll;
    this.radioDomain = radioDomain;
    this.area = area;
    this.organizationOrigin = organizationOrigin;
    this.concernedPersonName = concernedPersonName;
    this.documentNumber = documentNumber;
    this.processNumber = processNumber;
    this.subject =  subject;
    this.documentDateInitial =  documentDateInitial;
    this.documentDateEnd =  documentDateEnd;
    this.page = page
  }
}