import FormRegistration from '../../components/FormRegistration';

type FormProps = {
  setSearched: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: any
}

export function Registration({ setSearched, setOpen }: FormProps) {
  
  return (
    <div>
      <FormRegistration setSearched={setSearched} setOpen={setOpen}/>
    </div>
  )
};
