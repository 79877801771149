import {
  createContext,
  useState,
  useMemo,
  useCallback,
  ReactNode,
  useEffect,
  useRef
} from 'react';

export type AlertType = {
  message:   ReactNode
  autoHide?: boolean
  alertType: string
};

export type AlertContextType = {
  alert: AlertType | undefined;
  isOpen?: boolean;

  createAlert: (alert: AlertType) => void;
  closeAlert?: () => void;
};

export const AlertContext = createContext<AlertContextType | undefined>(
  undefined
);

export const AlertProvider = (props: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertType | undefined>(undefined);

  const timeout = useRef(0);

  const createAlert = useCallback((alert: AlertType) => {
    setAlert(alert);
    setIsOpen(true);
  }, []);

  const closeAlert = useCallback(() => {
    setAlert(undefined);
    setIsOpen(false);
  }, []);

  const context = useMemo(
    () => ({
      isOpen,
      alert,
      createAlert,
      closeAlert
    }),
    [isOpen, alert, createAlert, closeAlert]
  );

  useEffect(() => {
    if (alert && alert.autoHide) {
      timeout.current = window.setTimeout(() => {
        setIsOpen(false);
        setAlert(undefined);
      }, 5000);
    }
  }, [alert, timeout]);

  return (
    <AlertContext.Provider value={context}>
      {props.children}
    </AlertContext.Provider>
  );
};
