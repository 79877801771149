import { useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionBody, Card, Spinner } from '@material-tailwind/react';
import { BiDownload } from 'react-icons/bi'
import { convertDate, formatDocumentNumber } from '../../utils/masks'
import { useDonwloadLaw } from '../../hooks/useDonwloadLaw';

type ProcessProp = {
  name: string;
  documentNumber: string;
  documentDate: string;
  subject: string;
  domainName: string;
  organizationName: string;
  description: string;
  idAlfresco: string;
}
 
const AccordionLaw = ({ name, documentNumber, description, documentDate, subject, domainName, organizationName, idAlfresco }: ProcessProp) => {
  const open = useMemo(() => true, []);
  const [ openText, setOpenText ] = useState(true)
  const { loading, downloadPdfLaw } = useDonwloadLaw();

  const donwloadLaw = async (id_alfresco: string) => {
    await downloadPdfLaw(id_alfresco)
  }
  
  return (
    <Accordion open={open} className='py-0 ml-5 mr-5 w-auto'>
      <Card onClick={() => setOpenText(true)} className="rounded-none rounded-md bg-gray-555 font-bold flex flex-row justify-between">
        <div className='cursor-pointer ml-4 mt-1 mb-1 flex items-center w-52 sm:w-full text-white text-sm'>
          {description}
        </div>
        <div className='flex flex-row-reverse'>
          {loading ? (
              <Spinner className="mr-4 mt-2 mb-1 h-4 w-4 text-white"/>
            ) : (
              <BiDownload className="mr-4 mt-2 mb-1 h-5 w-5 cursor-pointer text-white" onClick={() => donwloadLaw(idAlfresco)}/>
            )
          }
        </div>
      </Card>
      <AccordionBody className='py-0 mb-3'>
        <Card className='rounded-none'>
          <div className='hidden sm:block'>
            <table className="table-fixed text-left ml-4 mr-4 mt-3">
              <thead>
                <tr>
                  <th className="w-[4%] text-green-555">Tipo</th>
                  <th className="w-[8%] text-green-555">Nome do Interessado</th>
                  <th className="w-[10%] text-green-555">Número do Documento</th>
                  <th className="w-[10%] text-green-555">Data do Documento</th>
                  <th className="w-[10%] text-green-555">Órgão de Origem</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="w-[4%] text-gray-400">{domainName}</td>
                  <td className="w-[8%] text-gray-400 truncate whitespace-normal">{name}</td>
                  <td className="w-[10%] text-gray-400">{formatDocumentNumber(documentNumber)}</td>
                  <td className="w-[5%] text-gray-400">{convertDate(documentDate)}</td>
                  <td className="w-[4%] text-gray-400">{organizationName}</td>
                </tr>
              </tbody>
            </table>
            <p className='mt-2 font-bold text-green-555 ml-4 mr-4'>Assunto</p>
            <p className={`mt-2 mb-2 ml-4 mr-4 text-gray-400 text-justify leading-tight ${openText ? 'line-clamp-3' : 'line-clamp-none'} `}>{subject}</p>
            <div className='font-bold text-gray-400 ml-4 mr-4 mt-1 mb-3 flex justify-end cursor-pointer' onMouseDown={() => setOpenText(!openText)}>
              { (openText && subject.length > 550) && ('Continue lendo...') }
            </div>
          </div>
          <div className='sm:hidden'>
            <p className='mt-2 font-bold text-green-555 ml-4 mr-4'>Tipo</p>
            <p className='text-gray-400 ml-4 mr-4'>{domainName}</p>

            <p className='mt-2 font-bold text-green-555 ml-4 mr-4'>Nome do Interessado</p>
            <p className='text-gray-400 ml-4 mr-4 w-64 truncate'>{name}</p>

            <p className='mt-2 font-bold text-green-555 ml-4 mr-4'>Número do Documento</p>
            <p className='text-gray-400 ml-4 mr-4'>{formatDocumentNumber(documentNumber)}</p>

            <p className='mt-2 font-bold text-green-555 ml-4 mr-4'>Data do Documento</p>
            <p className='text-gray-400 ml-4 mr-4'>{convertDate(documentDate)}</p>

            <p className='mt-2 font-bold text-green-555 ml-4 mr-4'>Órgão de Origem</p>
            <p className='text-gray-400 ml-4 mr-4'>{organizationName}</p>

            <p className='mt-2 font-bold text-green-555 ml-4 mr-4'>Assunto</p>
            <div className='text-justify text-gray-400'>
              <p className={`leading-tight mb-2 ml-4 mr-4 w-auto sm:w-full ${openText ? 'line-clamp-3' : 'line-clamp-none'} `}>{subject}</p>
            </div>
            <div className='font-bold text-gray-400 ml-4 mr-4 mt-1 mb-3 flex justify-end cursor-pointer' onMouseDown={() => setOpenText(!openText)}>
              { (openText && subject.length > 50) && ('Continue lendo...') }
            </div>
          </div>
        </Card>
      </AccordionBody>
    </Accordion>
  );
}

export default AccordionLaw