import React, { useCallback, useEffect, useState } from 'react';
import { getOrganizationOrigins, getDomains, getAreas, getShowLaw } from '../services/service';
import { AreaDto } from '../dtos/areaDto.model';
import { DomainDto } from '../dtos/domainDto.model';
import { OrganizationOriginDto } from '../dtos/organizationOriginDto.model';
import { useAlertContext } from '../hooks/useAlert';
import { AlertType } from '..//models/alert';

export function useShowLaw() {
  const [ organizationOriginList, setOrganizationOriginList ] = useState<OrganizationOriginDto[]>();
  const [ domainList, setDomainList ] = useState<DomainDto[]>();
  const [ areaList, setAreaList ] = useState<AreaDto[]>();

  const [ documentDate, setDocumentDate ] = useState('');
  const [ documentNumber, setDocumentNumber ] = useState('');
  const [ processNumber, setProcessNumber ] = useState('');
  const [ subject, setSubject ] = useState('');
  const [ concernedPersonName, setConcernedPersonName ] = useState();
  const [ organizationOrigin, setOrganizationOrigin ] = useState<OrganizationOriginDto | null | undefined>();
  const [ area, setArea ] = useState<AreaDto | null | undefined>();
  const [ domain, setDomain ] = useState<DomainDto | null | undefined >();
  const [ idAlfresco, setIdAlfresco ] = useState<string>()
  const [ getIdLaw, setGetIDLaw ] = useState()
  const { createAlert } = useAlertContext()
  const [ id, setID ] = useState()
  
  React.useEffect(() => { handleShowLaw(id) }, [organizationOriginList, domainList, areaList])

  const showLaw = useCallback(
    async (idLaw: any) => {
      setID(idLaw)
      handleShowLaw(idLaw)
    },[]
  )

  useEffect(() =>{
    const fetchData = async()=>{
      const data = await getOrganizationOrigins()
      const domainData = await getDomains();
      const areaData = await getAreas();

      setOrganizationOriginList(data.data.data);
      setDomainList(domainData.data.data);
      setAreaList(areaData.data.data);
    }
    fetchData();
  },[id]);
  
  const handleShowLaw = async (idLaw:any) =>{
    if (idLaw) {
      try {
        const data = (await getShowLaw(idLaw)).data;
        
        setGetIDLaw(data.id)
        setSubject(data.subject)
        setArea(areaList?.find(area => area.value == data.area_id))
        setDomain(domainList?.find(domain => domain.value == data.domain_id))
        setOrganizationOrigin(organizationOriginList?.find(organization => organization.value == data.organization_origin_id))
        setConcernedPersonName(data.concerned_person_name)
        setDocumentDate(data.document_date)
        setDocumentNumber(String(data.document_number));
        setProcessNumber(String(data.process_number))
        setIdAlfresco(data.id_alfresco)
      } catch (error: any) {
        createAlert({
          message: error,
          alertType: AlertType.error,
          autoHide: true
        });
      } 
    }
  }

  return { 
    showLaw, 
    getIdLaw, 
    setGetIDLaw, 
    documentDate, 
    documentNumber, 
    processNumber, 
    subject, 
    concernedPersonName, 
    organizationOrigin, 
    area, 
    domain, 
    idAlfresco
  }
}