import { useAlertContext } from './useAlert';
import { AlertType } from '../models/alert';
import { DeleteLawDto } from '../dtos/deleteLaw.model';
import { deleteLaw } from '../services/service';
import { useCallback, useState, useMemo } from 'react';

export function useDeleteLaw() {
  const user_id = useMemo(() => localStorage.getItem('user_id'), [])
  const user_email = useMemo(() => localStorage.getItem('user_mail'), [])
  const user_name = useMemo(() => localStorage.getItem('user_name'), [])

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const { createAlert } = useAlertContext()

  const getDeleteLaw = useCallback(
    async (idLaw: any) => {
      handleDeleteLaw(idLaw)
      setOpen(false)
    },[]
  )

  const handleDeleteLaw = async (idLaw: number | undefined) => {
    const paramsDeleteLaw: DeleteLawDto = new DeleteLawDto(
      idLaw!,
      user_id!,
      user_name!,
      user_email!
    )
    
    try {
      const response = await deleteLaw(paramsDeleteLaw)
      createAlert({
        message: response.data.message,
        alertType: AlertType.success,
        autoHide: true
      });
    } catch (error: any) {
      setOpen(false)
      createAlert({
        message: 'Não foi possivel excluir!',
        alertType: AlertType.error
      });
    }
  }

  return { getDeleteLaw, open, handleOpen }
}