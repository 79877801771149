import { useAlertContext } from '../../hooks/useAlert';
import { createPortal }    from 'react-dom';
import { IoMdClose }       from 'react-icons/io';
import { FC }              from 'react';

export const Alert: FC = () => {
  const {
    alert,
    isOpen,
    closeAlert
  } = useAlertContext();

  if (isOpen && alert?.message) {
    return createPortal(
      <div className={`alert ${alert.alertType}`}>
        {alert?.message} <button onClick={closeAlert}><IoMdClose className="ml-5" /></button>
      </div>,
      document.body
    );
  }

  return null;
};
