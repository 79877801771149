
  export const maskBuildProcessNumber = (value:string) => {
    if (value.replaceAll('-', '').replaceAll('.', '').replaceAll('_', '').trim().length < 10) {
      return '99999999-99';
    } else if(value.replaceAll('-', '').replaceAll('.', '').replaceAll('_', '').trim().length < 14){
      return '99999999/99999';
    } else{
      return '99999.999999/9999-99';
    }
  };

  export const convertDate = (value:string) => {
    const p = value.split(/\D/g)
    return [p[2],p[1],p[0] ].join('/');
  };

  export const formatDocumentNumber = (value:string) => {
    if (value) {
      return value.replace(/(\d{4})(?=\d)/g, '$1/');
    } else {
      return '';
    }
  };

  export const formatProcessNumber = (value:string) => {
    if (value.length == 9) {
      return value.replace(/(\d{8})(?=\d)/g, '$1-');
    } else if (value.length == 12) {
      return value.replace(/(\d{8})(?=\d)/g, '$1/');
    } else if (value.length == 17) {
      return value.replace(/(\d{5})(\d{6})(\d{4})(\d{2})/g,'$1.$2/$3-$4');
    } else {
      return '';
    }
  };
