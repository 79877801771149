import { useCallback, useState } from 'react';
import { getPdfLaw } from '../services/service';
import { useAlertContext } from '../hooks/useAlert';
import { AlertType } from '../models/alert';

export function useDonwloadLaw() {
  const { createAlert } = useAlertContext();
  const [ loading, setLoading ] = useState(false)
  const [ idLoading, setIdLoading ] = useState('')

  const downloadPdfLaw = useCallback(
    async (id_alfresco: string) => {
      asyncPdfLaw(id_alfresco)
    },[]
  )

  const openPdfLaw = useCallback((data: BlobPart) => {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }, []);

  async function asyncPdfLaw(id_alfresco: string) {
    try {
      setLoading(true)
      setIdLoading(id_alfresco)
      const response = await getPdfLaw(id_alfresco)

      if ( response.status === 200 ) {
        openPdfLaw(response.data)
      } else {
        createAlert({
          message:   'Documento não encontrado!',
          alertType: AlertType.error
        });
      }
      setLoading(false)
      setIdLoading('')
    } catch (error) {
      createAlert({
        message:   'Documento não encontrado!',
        alertType: AlertType.error
      });
    }
  }

  return { downloadPdfLaw, loading, idLoading }
}
